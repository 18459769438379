// 这里是接口存放调用的地方

import request from '@/router/request'

// 帮助中心文章分类（help center）
export function category(data) {
    return request({
        url: '/api/article/category',
        method: 'post',
        data
    })
}
// 帮助中心文章列表（help center）
export function article(data) {
    return request({
        url: '/api/article/article',
        method: 'post',
        data
    })
}



// 获取商品分类列表 左侧
export function getCategoryApi(id) {
    return request({
        url: '/api/goods/getCategory?category_id=' + id,
        method: 'post',
        // data
    })
}
// 获取商品分类轮播 
export function getBannerApi(id) {
    return request({
        url: '/api/goods/getBanner?category_id=' + id,
        method: 'post',
    })
}

// 获取商品列表
export function getGoodsApi(data) {
    return request({
        url: '/api/goods/getGoods',
        method: 'post',
        data
    })
}
// 获取商品标签
export function getSpenApi(id) {
    return request({
        url: '/api/goods/getSpec?category_id=' + id,
        method: 'post',
    })
}

// 获取文章导航
export function getArticleIndexApi(data) {
    return request({
        url: '/api/article/index',
        method: 'post',
        data
    })
}
// 问题咨询 表单提交
export function getQuestionApi(data) {
    return request({
        url: '/api/question/question',
        method: 'post',
        data
    })
}
// 问题咨询 表单提交
export function getWholesaleApi(data) {
    return request({
        url: '/api/question/wholesale',
        method: 'post',
        data
    })
}
// 获取文章内容
export function getArticleOneApi(id) {
    return request({
        url: '/api/article/articleOne?article_id=' + id,
        method: 'post',
    })
}
// 邮箱订阅
export function getEmailApi(email) {
    return request({
        url: '/api/question/email?email=' + email,
        method: 'post',
    })
}
// 获取商品分类  一级二级
export function getGoodsCataApi(data) {
    return request({
        url: '/api/goods/index',
        method: 'post',
    })
}
// 获取商品详情
export function getGoodsDetailsApi(id) {
    return request({
        url: '/api/goods/goodsOne?gid=' + id,
        method: 'post',
    })
}
// 提交订单
export function addOrderListApi(data) {
    return request({
        url: '/api/user/orderadd',
        method: 'post',
        data
    })
}
// 商品规格 检索 
export function postGoodsSpecSearchApi(data) {
    return request({
        url: '/api/goods/sku',
        method: 'post',
        data
    })
}
// 商品规格 检索 
export function postCustomDataApi(data) {
    return request({
        url: '/api/question/single',
        method: 'post',
        data
    })
}




// 首页
// export function index() {
//     return request({
//         url: '/api/index/index',
//         method: 'get',
//     })
// }