<template>
  <!-- <div id="app"> -->
  <router-view></router-view>
  <!-- </div> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "app",
  components: {
    // HelloWorld
  },
};
</script>

<style>
@font-face {
  font-family: "VisbyCF";
  src: url("./assets/font/VisbyCF.ttf"); /* IE9 */
}
* {
  padding: 0;
  margin: 0;
  font-family: "VisbyCF";
}
.el-message-box__btns button:nth-child(2) {
  background-color: #f29d35 !important;
  border-color: #f29d35;
}
body {
  overflow-x: hidden;
}
.disnav {
  margin-top: 15px !important;
}

/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
