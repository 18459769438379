<template>
  <div>
    <div class="headers">
      Reliable Hair Replacements Factory & Wholesale Supplier Since 1989
    </div>
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      style="z-index: 999; padding-left: 2%"
    >
      <router-link to="/" class="navbar-brand">Foreign trade mall</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto" style="width: 40%">
          <!-- men -->
          <li class="nav-item dropdown Help">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownss"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
              style="font-weight: 600; color: #000"
            >
              men
            </a>
            <div
              class="dropdown-menu helps"
              style="padding: 0 0; margin: 0 0; border: none"
              aria-labelledby="navbarDropdownss"
            >
              <div
                class="dropdown-menu helpsfour"
                style="width: 300%; font-size: 14px; color: #606060"
                aria-labelledby="navbarDropdownss"
              >
                <!-- <el-row :gutter="20">
                  <el-col
                    :span="8"
                    v-for="(item, index) in menList"
                    :key="index"
                    ><li
                      class="dropdown-item"
                      @click="navClick(item.category_id, item.parent_id)"
                    >
                      {{ item.name }}
                    </li></el-col
                  >
                </el-row> -->
                <div>
                  <span class="dropdown-item">By Feature</span>
                  <router-link
                    :to="{ name: 'Stock', query: { id: 12, parentId: 1 } }"
                    class="dropdown-item"
                    >Stock</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 13, parentId: 1 } }"
                    class="dropdown-item"
                    >Custom</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 14, parentId: 1 } }"
                    class="dropdown-item"
                    >Repair</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 15, parentId: 1 } }"
                    class="dropdown-item"
                    >Duplication</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 16, parentId: 1 } }"
                    class="dropdown-item"
                    >Afro</router-link
                  >
                </div>
                <div>
                  <span class="dropdown-item">By Base</span>
                  <router-link
                    :to="{ name: 'Stock', query: { id: 17, parentId: 1 } }"
                    class="dropdown-item"
                    >Lace</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 18, parentId: 1 } }"
                    class="dropdown-item"
                    >Skin</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 19, parentId: 1 } }"
                    class="dropdown-item"
                    >Mono</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 20, parentId: 1 } }"
                    class="dropdown-item"
                    >Silk Base</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 21, parentId: 1 } }"
                    class="dropdown-item"
                    >Bionic Scalp</router-link
                  >
                </div>
                <div>
                  <span class="dropdown-item">By Coverage</span>
                  <router-link
                    :to="{ name: 'Stock', query: { id: 22, parentId: 1 } }"
                    class="dropdown-item"
                    >Frontal & Partial</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 23, parentId: 1 } }"
                    class="dropdown-item"
                    >Crown & Top</router-link
                  >
                  <router-link
                    :to="{ name: 'Stock', query: { id: 76, parentId: 1 } }"
                    class="dropdown-item"
                    >Full Cap</router-link
                  >
                </div>
              </div>
            </div>
          </li>
          <!-- women  -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdowns"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
              style="font-weight: 600; color: #000"
            >
              Women
            </a>
            <div
              class="dropdown-menu helps"
              style="padding: 0 0; margin: 0 0; border: none; width: 700px"
              aria-labelledby="navbarDropdownss"
            >
              <div
                class="dropdown-menu helpsfour"
                style="width: 700px; font-size: 14px; color: #606060"
                aria-labelledby="navbarDropdowns"
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    flex-wrap: wrap;
                  "
                >
                  <router-link
                    to="/Hairless"
                    class="dropdown-item"
                    style="width: auto; margin: 5px 10px"
                    >Hair Loss & Thinning Hair</router-link
                  >

                  <router-link
                    v-for="(item, index) in womenList"
                    :key="index"
                    :to="{
                      name: 'Intergration',
                      query: { id: item.category_id, parentId: item.parent_id },
                    }"
                    class="dropdown-item"
                    style="width: auto; margin: 5px 8px"
                    >{{ item.name }}</router-link
                  >
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <router-link to="/Extensions?id=4&parentId=4" class="dropdown-item"
              >Hair Extensions & Bulk Hair</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/Access?id=5&parentId=5" class="dropdown-item"
              >Accessories</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/Wholesale" class="dropdown-item"
              >Wholesale</router-link
            >
          </li>
          <!--  Help Center -->
          <li class="nav-item dropdown Help">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownss"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
              style="font-weight: 600; color: #000"
            >
              Help Center
            </a>
            <div
              class="dropdown-menu helps"
              style="padding: 0 0; margin: 0 0; border: none"
              aria-labelledby="navbarDropdownss"
            >
              <div
                class="helpsfour"
                style="
                  font-size: 14px;
                  color: #606060;
                  border: 1px solid rgba(0, 0, 0, 0.15);
                  border-radius: 0.25rem;
                  padding: 0px 15px;
                "
              >
                <div
                  v-for="(item, index) in helpslist"
                  :key="index"
                  @click="gopagedetail()"
                >
                  <div class="dropdown-item" style="color: #000">
                    {{ item.name }}
                  </div>
                  <div
                    v-for="(items, findex) in item.list"
                    :key="findex"
                    @click="gowenz(items.article_id)"
                    style="padding-bottom: 10px"
                  >
                    <li
                      style="
                        max-width: 220px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ items.article_title }}
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <router-link to="/Contact" class="dropdown-item"
              >Contact Us</router-link
            >
          </li>
        </ul>
      </div>
      <div class="search">
        <!-- <form>
          <input
            type="text"
            v-model="inputname"
            style="border-radius: 20px"
            class="form-control form-control-sm"
            placeholder="search"
          />
          <input type="submit" value="" hidden />
        </form> -->
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          @submit.native.prevent
        >
          <!-- <img src="../assets/index/searchs.png" alt=""  /> -->
          <el-form-item label="">
            <el-input v-model="formInline.keywords" placeholder="keywords"
              ><el-button
                slot="append"
                icon="el-icon-search"
                @click="gosearch"
              ></el-button
            ></el-input>
            <!-- @change="gosearch" -->
          </el-form-item>
        </el-form>
        <div class="cry-header">
          <el-dropdown trigger="click">
            <el-button type="primary" class="userCenter">
              <i class="el-icon-user"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="dropDownUl">
              <el-dropdown-item
                ><span @click="userClick(1)">
                  user center</span
                ></el-dropdown-item
              >
              <!-- <el-dropdown-item @click="userClick(2)"
                >order center</el-dropdown-item
              > -->
              <el-dropdown-item
                ><span @click="userClick(3)">log out</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { category, getArticleIndexApi, getCategoryApi } from "../api/index";
import { postLogoutApi } from "../api/user";
export default {
  name: "Nav",
  data() {
    return {
      inputname: "",
      helps: [],
      helpslist: [],
      article_id: "",
      name: "",
      id: "",
      goodsId: 0, //产品Id
      menList: [], //男士产品分类
      womenList: [], //女士产品分类
      formInline: {
        keywords: "",
      },
    };
  },
  created() {
    category().then((res) => {
      this.helps = res.data;
      this.id = res.data.category_id;
    });
    this.wlist();
    // this.getGoodsCategory(1);
    this.getGoodsCategory(2);
  },
  methods: {
    /**
     * 导航栏 文章/新闻/faq 点击
     */
    articleClick(item) {
      // console.log(item);
    },
    /**
     * 导航点击 跳转
     * @id 产品分类id
     */
    navClick(id, pId) {
      this.$router.push({
        path: "/Stock?id=" + id + "&parentId=" + pId,
      });
    },
    gowenz(id) {
      this.$router.push({
        path: "/Attaching?id=" + id,
      });
    },
    gosearch() {
      if (this.$route.name.includes("product")) {
        // this.$route.query.goods_name = "123456";
        this.$router.replace({
          name: "product",
          query: {
            goods_name: this.formInline.keywords,
          },
        });
        this.$router.go(0);
      } else {
        this.$router.push({
          path: "/product?goods_name=" + this.formInline.keywords,
        });
      }
    },
    gopagedetail() {},
    // 获取文章导航
    wlist() {
      var data = {
        category_id: 1,
      };
      getArticleIndexApi().then((res) => {
        this.helpslist = res.data;
        // console.log(res.data, "文章");
        this.helpslist.forEach((item) => {
          // console.log(item, "item");
        });
        // this.article_id = res.data.article_id
        this.$emit("gethelpslist", this.helpslist);
      });
    },
    /**
     * 获取产品分类
     *
     */
    getGoodsCategory(id) {
      getCategoryApi(id).then((res) => {
        if (res && res.code && res.code == 1) {
          if (id == 1) {
            this.menList = res.data;
          } else if (id == 2) {
            this.womenList = res.data;
          }
        }
      });
    },
    /**
     * 个人中心点击
     */
    userClick(uid) {
      if (uid == 1) {
        // this.$route.push({ name: "account" });
        this.$router.push({ name: "account" });
      } else if (uid == 3) {
        let token = localStorage.getItem("token");
        if (token) {
          postLogoutApi({ token: token }).then((res) => {
            if (res && res.code && res.code == 1) {
              localStorage.removeItem("token");
              this.$message("Exit the success !");
              this.$router.push({ name: "usercenter" });
            }
          });
        } else {
          let that = this;
          this.$confirm(
            "Please login first! Whether to switch to the login page?",
            "Tip",
            {
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then(() => {
              that.$router.push({ name: "usercenter" });
            })
            .catch(() => {});
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.dropdown .dropdown-item.active,
.dropdown .dropdown-item:active {
  background-color: #f29d35;
}
.headers {
  height: 50px;
  line-height: 50px;
  background-color: #012340;
  padding-left: 2%;
  color: #fff;
}
a:hover {
  color: #f29d35;
  background-color: #f8f9fa;
}
.navbar-nav li {
  text-align: left;
  cursor: pointer;
}
.navbar-nav .el-col .dropdown-item {
  padding-left: 15px;
}
.dropdown-item {
  padding-top: 8px;
  padding-left: 0.2rem;
  font-weight: 600;
  text-transform: capitalize;
}
.search {
  width: 20%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .userCenter {
    background-color: #f8f9fa;
    border: 1px solid #e8eaee;
    padding: 8px;
    border-radius: 50%;
    margin-left: 10px;
    // color: #fff;
    i {
      color: #212529;
      font-size: 18px;
    }
  }

  img {
    width: 7%;
    position: absolute;
    top: 3px;
    left: 2%;
    cursor: pointer; //变小手
  }
  input {
    width: 55%;
    text-indent: 2rem;
  }
}
.helpsfour {
  display: flex;
  background-color: #fff;
  justify-content: space-around;
  div {
    text-align: left;
    padding: 3px 10px;
  }
  .dropdown-item {
    padding: 5px 0;
  }
}
// 10221018 updata
.el-form--inline .el-form-item {
  margin: 0px;
}
.navbar-expand-lg {
  padding: 15px 0px;
  border-bottom: 1px solid;
  border-bottom-color: rgba(1, 35, 64, 0.09);
}
.dropDownUl {
  li {
    font-size: 16px;
    color: #012340;
    text-transform: capitalize;
    &.el-dropdown-menu__item:focus,
    &.el-dropdown-menu__item:not(.is-disabled):hover {
      color: #fff;
      background-color: #f29d35;
    }
  }
}
</style>