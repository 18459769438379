<template>
  <div class="home">
    <Nav />
    <div>
      <!-- <div
          id="carouselExampleInterval"
          class="carousel slide"
          data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" data-interval="10000">
              <img
                src="../../assets/index/stockbanner.jpg"
                class="d-block w-100"
                alt=""   />
            </div>
            <div class="carousel-item" data-interval="2000">
              <img
                src="../../assets/index/banner@2x.png"
                class="d-block w-100"
                alt=""
              />
            </div>
            <div class="carousel-item">
              <img
                src="../../assets/index/banner@2x.png"
                class="d-block w-100"
                alt=""
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-target="#carouselExampleInterval"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-target="#carouselExampleInterval"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </button>
        </div> -->
      <!-- pc banner -->
      <div
        id="carouselExampleInterval"
        class="carousel slide"
        data-ride="carousel"
        style="background-color: #fff"
      >
        <div
          class="carousel-inner bananan"
          v-for="(item, index) in banners"
          :key="index"
        >
          <div class="carousel-item active posit posit1">
            <img
              :src="item.img"
              style="height: 600px; width: 100%"
              class="d-block w-100"
            />
            <router-link to="/Men"
              ><button>{{ item.btn }}</button></router-link
            >
          </div>
          <div class="carousel-item posit posit2">
            <img
              style="height: 600px; width: 100%"
              :src="item.img2"
              class="d-block w-100"
            />
            <router-link to="/About"
              ><button>{{ item.btn2 }}</button></router-link
            >
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-target="#carouselExampleInterval"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-target="#carouselExampleInterval"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </button>
      </div>
      <!-- 手机端banner -->
      <div
        id="carouselExampleIntervalss"
        class="carousel slide"
        data-ride="carousel"
        style="background-color: #fff"
      >
        <div
          class="carousel-inner bananans"
          v-for="(item, index) in banners"
          :key="index"
        >
          <div class="carousel-item active posit posit1">
            <img :src="item.img" style="width: 100%" class="d-block w-100" />
            <router-link to="/Men"
              ><button>{{ item.btn }}</button></router-link
            >
          </div>
          <div class="carousel-item posit posit2">
            <img
              style="width: 100%"
              :src="item.img2"
              class="d-block w-100"
              alt=""
            />
            <router-link to="/About"
              ><button>{{ item.btn2 }}</button></router-link
            >
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-target="#carouselExampleIntervalss"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-target="#carouselExampleIntervalss"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </button>
      </div>

      <div style="background-color: #fff; width: 100%">
        <div class="aboutNews" v-for="(item, index) in abouts" :key="index">
          <div style="padding-top: 30px">
            <h2>{{ item.titles }}</h2>
            <h5>{{ item.h5 }}</h5>
          </div>
          <div class="containers">
            <div class="row" style="background-color: #fff">
              <!-- 视频播放器配置 -->
              <div class="video">
                <video-player
                  class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
                  @timeupdate="onPlayerTimeupdate($event)"
                  @ready="playerReadied"
                  style="height: 650px"
                ></video-player>
                <!-- <b-button style="margin-left:40%;margin-top:5px" variant="outline-primary" @click="goBack">后退</b-button>
                  <b-button style="margin-left:20px;margin-top:5px" variant="outline-primary" @click="goFast">快进</b-button> -->
              </div>
              <div class="cont">
                <div class="cont-t" style="font-size: 13px">
                  {{ item.content }}
                </div>
                <div
                  style="text-align: left; padding-top: 10px; font-size: 13px"
                >
                  {{ item.bottom }}
                </div>
                <div style="text-align: left; font-size: 14px">
                  {{ item.Email }}
                </div>
                <div style="text-align: left; font-size: 14px">
                  {{ item.Telephone }}
                </div>
                <div class="cont-btn">
                  <!-- <router-link to="/Newtimehair"
                      ><button type="button" class="btn btn-outline-warning">
                        FACTORY VR TOUR
                      </button>
                    </router-link> -->
                  <router-link to="/About">
                    <button type="button" class="btn btn-outline-warning">
                      MORE ABOUT US
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: #fff; width: 100%">
        <div class="menToupee">
          <h2>MEN’S TOUPEE WHOLESALE</h2>
          <!-- 选项卡切换 -->
          <div class="menu">
            <span @click="btn(1)" :class="{ show: tab === 1 }">
              <div
                class="navimgs"
                v-for="(item, index) in navimgs"
                :key="index"
              >
                <img :src="item.laceimg" alt="" class="laceimg" />
                <img :src="item.laceimg1" alt="" class="laceimg1" />
              </div>
            </span>
            <span @click="btn(2)" :class="{ show: tab === 2 }" class="tabimg2">
              <div
                class="skinings"
                v-for="(item, index) in skinings"
                :key="index"
              >
                <img :src="item.skining" alt="" class="skining" />
                <img :src="item.skining1" alt="" class="skining1" />
              </div>
            </span>
            <span @click="btn(3)" :class="{ show: tab === 3 }" class="tabimg3">
              <div
                class="monoimgs"
                v-for="(item, index) in monoimgs"
                :key="index"
              >
                <img :src="item.monoimg" alt="" class="monoimg" />
                <img :src="item.monoimg1" alt="" class="monoimg1" />
              </div>
            </span>
            <span @click="btn(4)" :class="{ show: tab === 4 }" class="tabimg4">
              <div
                class="silkimgs"
                v-for="(item, index) in silkimgs"
                :key="index"
              >
                <img :src="item.silkimg" alt="" class="silkimg" />
                <img :src="item.silkimg1" alt="" class="silkimg1" />
              </div>
            </span>
          </div>
          <!-- 选项卡切换 -->
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
            style="40%"
          >
            <div class="carousel-inner">
              <div class="carousel-item active" style="60%" @click="btn(1)">
                <img
                  src="../../src/assets/lace.png"
                  class="d-block w-10"
                  style="margin: 0 auto"
                />
              </div>
              <div class="carousel-item" @click="btn(2)">
                <img
                  src="../../src/assets/skin.png"
                  class="d-block w-10"
                  style="margin: 0 auto"
                />
              </div>
              <div class="carousel-item" @click="btn(3)">
                <img
                  src="../../src/assets/mono.png"
                  class="d-block w-10"
                  style="margin: 0 auto"
                />
              </div>
              <div class="carousel-item" @click="btn(4)">
                <img
                  src="../../src/assets/silk.png"
                  class="d-block w-10"
                  style="margin: 0 auto"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </button>
          </div>
          <div class="contenttitle">
            <span v-if="tab == 1">
              <collapse>
                <div class="container" style="color: #000; text-align: left">
                  Lace toupees are becoming extremely popular with men
                  worldwide. WIGSZONE offer comfort and a realistic looking
                  men’s hair replacements, <br />
                  <span v-show="isActive">
                    <span
                      style="color: #1f2f52; font-size: 18px; font-weight: bold"
                      >Extremely Breathable and light weight. </span
                    ><br />
                    You’ll typically find lace hair piece bases made of French
                    lace material. Swiss lace is also available, which uses a
                    finer thread for a thinner feel, but can be less durable.<br />
                  </span>
                </div>
              </collapse>
              <span
                v-if="showBtn"
                class="btning"
                @click="showorless()"
                style="
                  color: #3e4c6a;
                  text-align: right;
                  width: 100%;
                  font-size: 18px;
                "
              >
                {{ changeshow }}
              </span>
              <div class="divbox">
                <div
                  class="allimg"
                  v-for="(item, index) in listing"
                  :key="index"
                >
                  <router-link to="/Viewdetail" class="imgs">
                    <img :src="item.imgs" alt="" class="a1" />
                  </router-link>
                  <router-link to="/Viewdetail" class="imging">
                    <img :src="item.imging" alt="" class="a2" />
                  </router-link>
                  <p style="width: 250px; color: #7d8daf; text-align: center">
                    {{ item.titles }}
                  </p>
                </div>
              </div>
              <div class="divbox2">
                <div
                  class="allimg"
                  v-for="(item, index) in listing"
                  :key="index"
                >
                  <router-link to="/Viewdetail" class="imgs">
                    <img :src="item.imgs" alt="" class="a1" />
                  </router-link>
                  <router-link to="/Viewdetail" class="imging">
                    <img :src="item.imging" alt="" class="a2" />
                  </router-link>
                  <p style="width: 250px; color: #7d8daf; text-align: center">
                    {{ item.titles }}
                  </p>
                </div>
              </div>
            </span>
            <span v-if="tab == 2">
              <collapse>
                <div class="container" style="color: #000; text-align: left">
                  Skin base hair systems are extremely popular with men for
                  their user-friendly design and durability.  Made of
                  polyurethane, skin toupees for men come in a wide variety of
                  skin thickness levels that give the look of real human skin.
                  Skin bases are easy to use and resist wear and tear. <br />
                  <span v-show="isActive2">
                    <span style="color: #1f2f52; font-size: 18px"
                      >Users enjoy how easy skin toupees are to clean and wear.
                      Gluing hair pieces onto the skin provides a level of
                      unmatched worry-free wearing, which is why it’s such a
                      popular option. It only takes the wearer a few minutes to
                      position and glue to wear and only a moment to wash off
                      when they’re through wearing. Skin base men’s toupee
                      couldn’t be easier to use and maintain, Undetectable and
                      Long-lasting, User-friendly Adding to the stress-free
                      experience of a skin base is just how undetectable the
                      base can be. The thinnest skin base comes in at an
                      astounding 0.03 mm thickness, making it easy to see how
                      imperceptible it is on the head.  The thinner the base,
                      the more natural-looking the hair toupee will look and
                      feel. That’s why skin hair replacement system for men is
                      one of the most popular types of men’s hair systems in our
                      hairpiece factory. Skin toupees offer a low-maintenance
                      option with high durability. Wearers can count on their
                      skin base realistic men’s wigs to last without having to
                      worry much about protecting delicate parts. The thicker
                      the skin base, the more durable it will be.
                    </span>
                  </span>
                </div>
              </collapse>
              <span
                v-if="showBtn"
                class="btning"
                @click="isActive2 = !isActive2"
                style="
                  color: #3e4c6a;
                  text-align: right;
                  padding-right: 20%;
                  width: 100%;
                  font-size: 20px;
                "
                >show more
              </span>
            </span>
            <span v-if="tab == 3">
              <collapse>
                <div class="container" style="color: #000; text-align: left">
                  Monofilament toupee for men, commonly known as mono are
                  gaining in popularity. That’s due to its durable nature that’s
                  very resistant to tearing. Your customers will appreciate the
                  new mono hybrid designs that often combine the best elements
                  of skin and lace materials, to make for a multi-purpose toupee
                  wig that’s both durable and realistic along with being
                  comfortable and breathable.   <br />
                  <span v-show="isActive2">
                    <span style="color: #1f2f52; font-size: 18px"
                      >Mono is a permeable material, which means although it may
                      not have as many openings as lace, it still allows for air
                      flow and the dissipation of heat. Mono base is the best
                      choice for the customer looking for a hair loss solution
                      that will truly durable and long-lasting. A thin skin
                      perimeter is applied for easy application and gluing and a
                      French lace front to create the most realistic looking
                      front hairline.  Silk Top: Silk top hair systems are
                      designed for the most discriminating of wearers, those who
                      want the most realistic look. The silk top human hair
                      system comes with a base consisting of two layers of
                      highly durable and realistic silk mono that imitates a
                      user’s scalp complexion. The exceptionally natural look is
                      achieved through an intricate sewing technique where each
                      individual strand of hair is tightly knotted onto the
                      under layer before passing through the top layer of the
                      base. This gives the appearance that each strand of hair
                      is “growing” right from the scalp which is extremely
                      realistic & NO knots. Everyone knows that silk is prized
                      for its luxurious feel and softness to the touch. This is
                      true for silk top hair systems as well. Wearers love the
                      silky feel and the fact that its unique construction
                      design makes it extremely well ventilated and Comfortable
                      to wear. Silk is also extremely strong and durable, so a
                      well-cared silk top hair piece can be worn for nearly a
                      year without any quality problems arising.
                    </span>
                  </span>
                </div>
              </collapse>
              <span
                v-if="showBtn"
                class="btning"
                @click="isActive2 = !isActive2"
                style="
                  color: #3e4c6a;
                  text-align: right;
                  padding-right: 20%;
                  width: 100%;
                  font-size: 20px;
                "
                >show more
              </span></span
            >
            <span v-if="tab == 4">内容三</span>
          </div>
        </div>
      </div>
      <div class="bgimgbox">
        <p class="title">
          NOT SURE HOW TO ORDER OR NEW TO HAIR REPLACEMENT BUSINESS?
          <br />
          NOT SURE WHICH PRODUCT IS PERFECT FOR YOUR CUSTOMER? LET US HELP!
        </p>
        <div class="bgimgbox_img">
          <div v-for="(item, index) in bgings" :key="index" @click="gos(index)">
            <img
              :src="item.img"
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#gethelp"
            />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <!-- 弹出框 -->
        <div class="bgimg_model">
          <!-- Modal -->
          <div
            class="modal fade"
            id="gethelp"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-headers">
                  <h4 class="modal-title" id="exampleModalLabel">
                    Have a question? We're happy to help!
                  </h4>
                  <p>
                    Leave a message on the form below or
                    WhatsApp（+86-1509-2233-077）us. We will get back to you
                    within 24 hours!
                  </p>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <!-- input框 -->
                  <div class="CONTACT">
                    <h2>INQUIRIES & QUESTIONS</h2>
                    <div class="inputs">
                      <input
                        type="text"
                        v-model="one"
                        id=""
                        placeholder="Name*"
                      />
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Email Address*"
                      />
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Country*"
                        list="typelist"
                      />
                      <datalist id="typelist">
                        　　
                        <option v-for="(item, index) in country" :key="index">
                          {{ item.label }}
                        </option>
                      </datalist>
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Phone/whatsapp*"
                      />
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Business name"
                      />
                      <input
                        type="text"
                        name=""
                        id=""
                        list="inputlist"
                        placeholder="Select your business role..*"
                      />
                      <datalist id="inputlist">
                        　　
                        <option
                          v-for="(item, index) in inputlists"
                          :key="index"
                        >
                          {{ item.name }}
                        </option>
                      </datalist>
                    </div>
                    <textarea
                      type=""
                      placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
                    ></textarea>

                    <button @click="gethelp()">Submit</button>
                  </div>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bgimgbox2">
        <div class="pcshow" v-for="(item, index) in pcshows" :key="index">
          <!-- pc -->
          <h3>{{ item.title }}</h3>
          <p style="">{{ item.contents }}</p>
        </div>
        <!-- 手机端 -->
        <div class="mobshow">
          <p style="color: #01ac86; font-size: 20px; padding-top: 100px">
            WOMEN'S WIG WHOLESALE
          </p>
          <collapse>
            <p style="width: 60%; margin: 0 auto; color: #01ac86">
              <span v-show="Active">
                As one of the world-leading wholesale toupee and wig
                manulacturers in China with more than 10 years ofexperience in
                the hair wig industry,New Times Hair is where you cen wholesale
                wigs and toupees for women inbulk of top-class for
                fostory-diroct prices.We offer a very wide range of wholesale
                human hair wigs produots in numerous hair colors and sizes to
                fully cater to your customers’ noeds. They include human h</span
              >
            </p>
          </collapse>
          <span
            v-if="showBtn"
            class="btning"
            @click="Active = !Active"
            style="
              color: #3e4c6a;
              text-align: center;
              width: 100%;
              font-size: 17px;
            "
            >show>>
          </span>
        </div>
        <!-- pc -->
        <div class="pcstyle">
          <div class="womensmenu">
            <span
              @click="checkoutType(1)"
              :class="tabs === 1 ? 'typeActive' : ''"
            >
              <p>HUMAN HAIR WIG</p>
            </span>
            <span
              @click="checkoutType(2)"
              :class="tabs === 2 ? 'typeActive' : ''"
            >
              <p>MEDICAL WIG</p>
            </span>
            <span
              @click="checkoutType(3)"
              :class="tabs === 3 ? 'typeActive' : ''"
            >
              <p>HAIR TOPPER</p>
            </span>
            <span
              @click="checkoutType(4)"
              :class="tabs === 4 ? 'typeActive' : ''"
            >
              <p>HAIR LOSS&THIN HAIR</p>
            </span>
          </div>
          <div>
            <span v-if="tabs == 1">
              <div class="tabone">
                <div
                  v-for="(item, index) in womanimgs"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
            <span v-if="tabs == 2">
              <div class="tabone">
                <div
                  v-for="(item, index) in womanimgs2"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
            <span v-if="tabs == 3">
              <div class="tabone">
                <div
                  v-for="(item, index) in womanimgs3"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
            <span v-if="tabs == 4">
              <div class="tabone">
                <div
                  v-for="(item, index) in womanimgs4"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
          </div>
        </div>
        <!-- 手机端 -->
        <div class="phonestyle">
          <div class="womensmenu2">
            <div @click="but(1)" :class="{ active: tabs === 1 }">
              <p>Human Hair Wigs</p>
            </div>
            <div @click="but(2)" :class="{ active: tabs === 2 }">
              <p>silicone Wigs</p>
            </div>
            <div @click="but(3)" :class="{ active: tabs === 3 }">
              <p>Hair Topper</p>
            </div>
            <div @click="but(4)" :class="{ active: tabs === 4 }">
              <p>Hair Loss&Thin Hair</p>
            </div>
          </div>
          <div>
            <span v-if="tabs == 1">
              <div>
                <div
                  v-for="(item, index) in womanimgs"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
            <span v-if="tabs == 2">
              <div>
                <div
                  v-for="(item, index) in womanimgs2"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
            <span v-if="tabs == 3">
              <div>
                <div
                  v-for="(item, index) in womanimgs3"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
            <span v-if="tabs == 4">
              <div>
                <div
                  v-for="(item, index) in womanimgs4"
                  :key="index"
                  class="womanimgs"
                >
                  <img :src="item.img" alt="" />
                  <div class="tabs_one">
                    <p>{{ item.title }}</p>
                    <p>{{ item.content }}</p>
                  </div>
                  <button @click="views(index)">View</button>
                </div>
              </div>
              <div style="color: #ff0082; width: 100%; margin-top: 50px">
                <a
                  href="/"
                  style="color: #ff0082; text-decoration: none; font-size: 20px"
                  >more>></a
                >
              </div>
            </span>
          </div>
        </div>
      </div>
      <!-- pc端 HAIR EXTENSION & HAIR BULK-->
      <div style="background-color: #fff; width: 100%" class="pcHAIR">
        <div
          class="HairExtensions"
          v-for="(item, index) in HairExtening"
          :key="index"
        >
          <h2>{{ item.title }}</h2>
          <div class="Extensionspc">
            <div
              v-for="(item, index) in hair"
              :key="index"
              class="HairExtensions_harr asd"
            >
              <router-link to="/Supplies"
                ><img :src="item.imgs" alt=""
              /></router-link>
              <h4>{{ item.title }}</h4>
              <p class="optic">{{ item.conton }}</p>
            </div>
          </div>
          <div class="Extensionsmoble" style="">
            <div
              style="width: 100"
              v-for="(item, index) in hair"
              :key="index"
              class="HairExtensions_harr"
            >
              <img :src="item.imgs" alt="" />
              <h4>{{ item.title }}</h4>
            </div>
          </div>
          <h2 class="h2pc" style="margin: 80px 0">
            Why Choose Us As Your Wholesale Toupee Partner?
          </h2>
          <p class="h2moble" style="margin: 50px 0">
            Why Choose Us As Your Wholesale Toupee Partner?
          </p>
          <div class="pclv card-face" @click="handler()">
            <div
              v-for="(item, index) in lvbox"
              :key="index"
              class="lvbox card-face-front"
            >
              <img :src="item.imgs" alt="" />
              <h4>{{ item.title }}</h4>
              <p style="text-align: left">{{ item.content }}</p>
            </div>
          </div>
          <div class="moblelv">
            <div
              v-for="(item, index) in lvbox"
              :key="index"
              class="lvbox"
              style="width: 100%"
            >
              <img :src="item.imgs" alt="" />
              <h4>{{ item.title }}</h4>
              <p style="text-align: left">{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 手机端 HAIR EXTENSION & HAIR BULK-->
      <div style="background-color: #fff; width: 100%" class="phoneHAIR">
        <div
          class="HairExtensions"
          v-for="(item, index) in HairExtening"
          :key="index"
        >
          <h4>{{ item.title }}</h4>
          <div class="Extensionspc">
            <div
              v-for="(item, index) in hair"
              :key="index"
              class="HairExtensions_harr asd"
            >
              <router-link to="/Supplies"
                ><img :src="item.imgs" alt=""
              /></router-link>
              <h4>{{ item.title }}</h4>
              <p class="optic">{{ item.conton }}</p>
            </div>
          </div>
          <div class="Extensionsmoble" style="">
            <div
              style="width: 100"
              v-for="(item, index) in hair"
              :key="index"
              class="HairExtensions_harr"
            >
              <img :src="item.imgs" alt="" />
              <h4>{{ item.title }}</h4>
            </div>
          </div>
          <h2 class="h2pc" style="margin: 80px 0">
            Why Choose Us As Your Wholesale Toupee Partner?
          </h2>
          <p class="h2moble" style="margin: 50px 0">
            Why Choose Us As Your Wholesale Toupee Partner?
          </p>
          <div class="pclv card-face" @click="handler()">
            <div
              v-for="(item, index) in lvbox"
              :key="index"
              class="lvbox card-face-front"
            >
              <img :src="item.imgs" alt="" />
              <h4>{{ item.title }}</h4>
              <p style="text-align: left">{{ item.content }}</p>
            </div>
          </div>
          <div class="moblelv">
            <div
              v-for="(item, index) in lvbox"
              :key="index"
              class="lvbox"
              style="width: 100%"
            >
              <img :src="item.imgs" alt="" />
              <h4>{{ item.title }}</h4>
              <p style="text-align: left">{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="jionbg"></div>
      <!-- pc 透明背景 -->
      <div class="disp" v-for="(item, index) in displist" :key="index">
        <p style="padding-top: 100px">{{ item.p1 }}</p>
        <p style="color: #eac203">{{ item.p2 }}</p>
        <p>{{ item.p3 }}</p>
        <p>{{ item.p4 }}</p>
        <p>{{ item.p5 }}</p>
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          {{ item.button }}
        </button>
      </div>
      <!-- phone 透明背景 -->
      <div class="phonedisp" v-for="(item, index) in displist" :key="index">
        <p style="padding-top: 100px">{{ item.p1 }}</p>
        <p style="color: #eac203">{{ item.p2 }}</p>
        <p>{{ item.p3 }}</p>
        <p>{{ item.p4 }}</p>
        <p>{{ item.p5 }}</p>
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          {{ item.button }}
        </button>
      </div>
      <!-- Modal弹窗 -->
      <div
        class="modal fade"
        style="color: #000"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-scrollable"
          style="max-width: 800px"
        >
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
                <!-- 右上角关闭 -->
              </button>
            </div>
            <div class="modal-body" style="color: #000">
              <!-- input框 -->
              <div class="CONTACT">
                <h2>INQUIRIES & QUESTIONS</h2>
                <div class="inputs">
                  <!-- <input id="type" type="text" placeholder="请选择"> -->

                  <input type="text" v-model="one" id="" placeholder="Name*" />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Email Address*"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Country*"
                    list="typelist"
                  />
                  <datalist id="typelist">
                    　　
                    <option v-for="(item, index) in country" :key="index">
                      {{ item.name }}
                    </option>
                  </datalist>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Phone/whatsapp*"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Business name"
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    list="inputlist"
                    placeholder="Select your business role..*"
                  />
                  <datalist id="inputlist">
                    　　
                    <option v-for="(item, index) in inputlists" :key="index">
                      {{ item.name }}
                    </option>
                  </datalist>
                </div>
                <textarea
                  type=""
                  placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
                ></textarea>

                <button @click="submit()">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--pc PRODUCT & SERVICE -->
      <div class="pcPRODUCT">
        <div style="width: 90%; margin: 0 auto">
          <h2 style="padding: 20px 0">PRODUCT & SERVICE</h2>
          <p style="font-size: 12px">
            Wholesale No Surgical Hair Replacements For Men and Women. Your
            Trusted Supplier For Quality Hair！
          </p>
          <div class="upbox">
            <div class="headhair" v-for="(item, index) in content" :key="index">
              <div style="width: 45%; text-align: left; margin: 40px 0">
                <h3>{{ item.title }}</h3>
                <p>{{ item.content }}</p>
                <button class="upboxbtn">
                  <router-link to="/Men">VIEW MORE</router-link>
                </button>
              </div>
              <img :src="item.img" alt="" style="width: 45%; height: 480px" />
              <img :src="item.imgs" alt="" style="width: 45%; height: 480px" />
              <div style="width: 45%; text-align: left; line-height: 25px">
                <h3>{{ item.title2 }}</h3>
                <p>{{ item.content2 }}</p>
                <button class="upboxbtn">
                  <router-link to="../Women">VIEW MORE</router-link>
                </button>
              </div>
            </div>
            <div
              class="headhairmoble"
              v-for="(item, index) in content"
              :key="index"
            >
              <div style="width: 100%; text-align: left; margin: 40px 0">
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
                <p>
                  ln our toupee factory, ewery slingle item passes through
                  severalrounds of quality inspections before they 're ready for
                  sale.Asconsumer needs and expectations for hair toupees keep
                </p>
                <p>
                  changing and growing, New Times Hair Company never
                  stopseoxpanding our toupee production line.Based on trends
                  andmarket research, the New Times Hair company
                  continuouslylaunches new base designs that appeal to your
                  target consumer.Our stock hair replacement systems are
                  available in a largevariety of hair colors and base sizes to
                  meet the wearers"varyingneeds.
                </p>
                <button class="upboxbtn"><a href="/">VUE CUSTOM</a></button>
              </div>
              <img :src="item.img" alt="" style="width: 100%" />
              <img :src="item.imgs" alt="" style="width: 100%" />
              <div style="width: 100%; text-align: left">
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
                <p>
                  changing and growing, New Times Hair Company never
                  stopseoxpanding our toupee production line.Based on trends
                  andmarket research, the New Times Hair company
                  continuouslylaunches new base designs that appeal to your
                  target consumer.Our stock hair replacement systems are
                  available in a largevariety of hair colors and base sizes to
                  meet the wearers"varyingneeds.
                </p>
                <button class="upboxbtn"><a href="/">VUE CUSTOM</a></button>
              </div>
            </div>
            <div style="width: 40%"></div>
          </div>
        </div>
      </div>
      <!-- 手机端PRODUCT & SERVICE -->
      <div class="phonePRODUCT">
        <div style="width: 90%; margin: 0 auto">
          <h3 style="padding: 20px 0">PRODUCT & SERVICE</h3>
          <p style="font-size: 12px">
            Wholesale No Surgical Hair Replacements For Men and Women. Your
            Trusted Supplier For Quality Hair！
          </p>
          <div class="upbox">
            <div class="headhair" v-for="(item, index) in content" :key="index">
              <div>
                <h3 style="padding: 5px 0">{{ item.title }}</h3>
                <p>{{ item.content }}</p>
                <button class="upboxbtn">
                  <router-link to="/Men">VIEW MORE</router-link>
                </button>
              </div>
              <img :src="item.img" alt="" style="width: 45%; height: 480px" />
              <img :src="item.imgs" alt="" style="width: 45%; height: 480px" />
              <div style="width: 45%; text-align: left; line-height: 25px">
                <h3>{{ item.title2 }}</h3>
                <p>{{ item.content2 }}</p>
                <button class="upboxbtn">
                  <router-link to="../Women">VIEW MORE</router-link>
                </button>
              </div>
            </div>
            <div
              class="headhairmoble"
              v-for="(item, index) in content"
              :key="index"
            >
              <div style="width: 100%; text-align: left; margin: 40px 0">
                <h5 style="text-align: center">{{ item.title }}</h5>
                <p>{{ item.content }}</p>
                <p>
                  ln our toupee factory, ewery slingle item passes through
                  severalrounds of quality inspections before they 're ready for
                  sale.Asconsumer needs and expectations for hair toupees keep
                </p>
                <p>
                  changing and growing, New Times Hair Company never
                  stopseoxpanding our toupee production line.Based on trends
                  andmarket research, the New Times Hair company
                  continuouslylaunches new base designs that appeal to your
                  target consumer.Our stock hair replacement systems are
                  available in a largevariety of hair colors and base sizes to
                  meet the wearers"varyingneeds.
                </p>
                <button class="upboxbtn"><a href="/">VUE CUSTOM</a></button>
              </div>
              <img :src="item.img" alt="" style="width: 100%" />
              <img :src="item.imgs" alt="" style="width: 100%" />
              <div style="width: 100%; text-align: left">
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
                <p>
                  changing and growing, New Times Hair Company never
                  stopseoxpanding our toupee production line.Based on trends
                  andmarket research, the New Times Hair company
                  continuouslylaunches new base designs that appeal to your
                  target consumer.Our stock hair replacement systems are
                  available in a largevariety of hair colors and base sizes to
                  meet the wearers"varyingneeds.
                </p>
                <button class="upboxbtn"><a href="/">VUE CUSTOM</a></button>
              </div>
            </div>
            <div style="width: 40%"></div>
          </div>
        </div>
      </div>

      <div>
        <!-- WHOLESALE CUSTOMER FEEDBACK 轮播 -->
        <div style="background-color: #f7f7f7; width: 100%">
          <div
            class="WHOLESALE"
            style="padding: 50px 0"
            v-for="(item, index) in WHOLESALEs"
            :key="index"
          >
            <h2>{{ item.title }}</h2>
            <h4 style="padding-bottom: 40px">{{ item.p }}</h4>
            <div>
              <el-carousel
                :interval="5000"
                arrow="always"
                style="height: 700px"
              >
                <el-carousel-item
                  style="height: 700px"
                  v-for="(item, index) in listphoto"
                  :key="index"
                  class="alwasing"
                >
                  <div class="always">
                    <div class="always_box">
                      <img :src="item.img" alt="" />
                      <p>{{ item.content }}</p>
                      <p>{{ item.name }}</p>
                    </div>
                  </div>
                  <div class="always">
                    <div class="always_box">
                      <img :src="item.img2" alt="" />
                      <p>{{ item.content2 }}</p>
                      <p>{{ item.name2 }}</p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <!-- WHOLESALE CUSTOMER FEEDBACK 轮播 -->
        <div style="background-color: #f7f7f7; width: 100%">
          <div class="WHOLESALE2" style="padding: 50px 0">
            <h2>WHOLESALE CUSTOMER FEEDBACK</h2>
            <p>Hear voices and feedback from New Times Hair real customers.</p>
            <div>
              <el-carousel
                :interval="5000"
                arrow="always"
                style="height: 650px"
              >
                <el-carousel-item
                  style="height: 650px"
                  v-for="(item, index) in listphoto"
                  :key="index"
                  class="alwasing"
                >
                  <div class="always">
                    <div class="always_box">
                      <img :src="item.img" alt="" />
                      <p>{{ item.content }}</p>
                      <p>{{ item.name }}</p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
      <p class="pos_fixed">
        <img src="../../src/assets/index/20220624111123.png" alt="" />
      </p>
      <div class="WHYS" style="">
        <div v-for="(item, index) in WHYING" :key="index" class="WHYScontent">
          <h2>{{ item.title }}</h2>
          <p>{{ item.content }}</p>
        </div>
        <div class="Tsingboxdis">
          <div class="Tsingbox2" v-for="(item, index) in Hairicon" :key="index">
            <img :src="item.img" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <!-- input框 -->
      <div style="width: 100%; background-color: #f2f2f2">
        <!-- input框 -->
        <div class="CONTACT">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <!-- <input id="type" type="text" placeholder="请选择"> -->

            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.name }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>

          <button @click="submitss()">Submit</button>
        </div>
        <!-- input框 -->
        <div class="CONTACTs">
          <h2>INQUIRIES & QUESTIONS</h2>
          <div class="inputs">
            <input type="text" v-model="one" id="" placeholder="Name*" />
            <input type="text" name="" id="" placeholder="Email Address*" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Country*"
              list="typelist"
            />
            <datalist id="typelist">
              　　
              <option v-for="(item, index) in country" :key="index">
                {{ item.name }}
              </option>
            </datalist>
            <input type="text" name="" id="" placeholder="Phone/whatsapp*" />
            <input type="text" name="" id="" placeholder="Business name" />
            <input
              type="text"
              name=""
              id=""
              list="inputlist"
              placeholder="Select your business role..*"
            />
            <datalist id="inputlist">
              　　
              <option v-for="(item, index) in inputlists" :key="index">
                {{ item.name }}
              </option>
            </datalist>
          </div>
          <textarea
            type=""
            placeholder="Any questions or product demands/ sizel colorl length/ quantity/ pricel other information "
          ></textarea>
          <button @click="submits()">Submit</button>
        </div>
      </div>
      <div class="FAQ">
        <div class="FAQ_dis">
          <div
            v-for="(item, index) in FAQQ"
            :key="index"
            @click="godetail(index)"
          >
            <img :src="item.img" alt="" />
            <p>{{ item.content }}</p>
          </div>
        </div>
        <router-link to="/MOREs" class="FAQMARE">More>></router-link>
      </div>
      <div class="top" @click="toTop()">
        <img
          style="width: 3%; height: 50px"
          src="../../src/assets/index/xiala_un@2x.png"
          alt=""
        />
      </div>
      <div class="tops" @click="toTops()">
        <img src="../../src/assets/index/xiala_un@2x.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import collapse from "../../src/js/collapse.js";
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  name: "Home",
  props: ["url"],
  data() {
    return {
      one: "",
      typeList: [
        { title: "全部" },
        { title: "充值" },
        { title: "提现" },
        { title: "积分" },
      ],
      bgings: [
        {
          img: require("../../src/assets/index/gouwu@2x.png"),
          name: "Ordering Guide",
        },
        {
          img: require("../../src/assets/index//yiwen@2x.png"),
          name: "Get Help",
        },
      ],
      inputlists: [
        { name: "Select your business role" },
        { name: " Salon owner" },
        { name: "Hair stylist" },
        { name: "Storekeeper" },
        { name: "Online shop owner" },
        { name: "Wholesaler & Distributor" },
        { name: "lndividual wearer" },
        { name: "Others" },
      ],
      country: [],
      pcshows: [
        {
          title: "WOMEN'S WIG WHOLESALE",
          contents:
            "  WIGSZONE offer a very wide range of wholesale 100% real human hair wigs in numerous hair colors and sizes to fully cater to your needs.  They include human hair wigs, hair toppers, medical wigs, full lace  wigs, 360 lace wigs, lace front wigs, hair pieces for women and so on. By buying high quality women hair systems in bulk from us, your  business is sure to achieve higher levels of success!",
        },
      ],
      womanimgs: [
        {
          img: require("../../src/assets/index/0220805140215.jpg"),
          title: "FULL LACE WIG",
        },
        {
          img: require("../../src/assets/index/20220805140813.jpg"),
          title: "360 LACE WIG",
        },
        {
          img: require("../../src/assets/index/20220805140952.jpg"),
          title: "LACE FRONT WIG",
        },
        {
          img: require("../../src/assets/index/220805141007.jpg"),
          title: "MEDICAL WIG",
        },
      ],
      womanimgs2: [
        {
          img: require("../../src/assets/index/0220805141155.jpg"),
          title: "THIN SKIN MEDICAL WIG",
        },
        {
          img: require("../../src/assets/index/20220805141244.jpg"),
          title: "SILK Top MEDICAL WIG",
        },
        {
          img: require("../../src/assets/index/20220805141301.jpg"),
          title: "BIONIC SKIN WIG",
        },
        {
          img: require("../../src/assets/index/0220805141314.jpg"),
          title: "MONO MEDICAL WIG",
        },
      ],
      womanimgs3: [
        {
          img: require("../../src/assets/index/0220805141334.jpg"),
          title: "LACE HAIR TOPPER",
        },
        {
          img: require("../../src/assets/index/0220805141349.jpg"),
          title: "MONO HAIR TOPPER",
        },
        {
          img: require("../../src/assets/index/0220805141401.jpg"),
          title: "SILK Top HAIR TOPPER",
        },
        {
          img: require("../../src/assets/index/20220805141414.jpg"),
          title: "BIONIC HAIR TOPPER",
        },
      ],
      womanimgs4: [
        {
          img: require("../../src/assets/index/0220805141437.jpg"),
          title: "LACE HAIR TOPPER",
        },
        {
          img: require("../../src/assets/index/20220805141449.jpg"),
          title: "MONO HAIR TOPPER",
        },
        {
          img: require("../../src/assets/index/20220805141459.jpg"),
          title: "SILK Top HAIR TOPPER",
        },
        {
          img: require("../../src/assets/index/0220805141516.jpg"),
          title: "BIONIC Skin TOPPER",
        },
      ],
      HairExtening: [
        {
          title: "HAIR EXTENSION & HAIR BULK",
        },
      ],
      FAQQ: [
        {
          img: require("../assets/index/20220624145225.png"),
          content: "WHAT KIND OF HAIR SYSTEM IS SUITABLE FOR ME?",
        },
        {
          img: require("../assets/index/20220624150925.png"),
          content:
            "Toupees, wigs, hair extensions, hairpieces: Our hair systems are for everyone!",
        },
        {
          img: require("../assets/index/20220624150843.png"),
          content:
            "WIGSZONE MOST NATURAL HAIRLINE ARTS AND CRAFTS How WigsZone Create the Most Natural Hairline For Hair Systems?",
        },
      ],
      tabs: 1,
      isActive: false,
      isActive2: false,
      Active: false,
      tab: 1,
      showBtn: true,
      lay_type: 1,
      lay_types: 1,
      lay_type3: 1,
      lay_type4: 1,
      banners: [
        {
          img: require("../assets/index/220805114232.jpg"),
          btn: "View More",
          img2: require("../assets/logoimgs/2.png"),
          // img2: require("../assets/index/220805114232.jpg"),
          btn2: "Know more about our factory",
        },
      ],
      abouts: [
        {
          titles: "About TsingTao Hair WigsZone",
          h5: "Wholesale Man Toupees & Woman Wigs Manufacturer",
          content:
            "Thank you for visiting Wigszone.com - TsingTao Hair Factory Direct Online Store. Established in 1989, we have been committed to producing best quality hair systems, replacements, man toupees, women wigs, hairpieces, hair toppers, hair extensions and bulk hair. Our factory have our own proud brands Mr. Right Hair, Starlady and Queen La Hair, also we are doing OEM brands for many big companies all around the world.With business growing for over 30 years, we’re proud to have become a leading top quality non-surgical hair system manufacturer and wholesale supplier. We have most strict quality control in every process and excellent customer service. We are not just a supplier, we prefer be a good partner. With strong production team, as well as the R&D and QC teams ensure that we can provide customers high quality products continuously. Also, we have after-sale service team works 12 hours a day to confirm that all the problems you may meet will be solved properly in time. With rich experience in international trade, we ship worldwide to our customers from Europe, North America, Middle East and Asia...We cater to our wholesale customers including hair replacements companies, distributors, hair shop owners and salon owners. We welcome you to cooperate with us to expand your business and boost your profits!",
          bottom: "TSINGTAO HAIR WIGSZONE PROUDCTS CO., LTD.",
          Email: "Email: info@wigszone.com, order@wigszone.com, ",
          Telephone: "Telephone/WhatsApp: +86-1509-2233-077, +86-159-5329-0787",
        },
      ],
      //八个鼠标经过切换图片
      listing: [
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/NWS014-Silk-Top-Hair-System-with-Injected-Skin-and-Lace-Front-2-300x300.jpg"),
          imging: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/DURO-LACE-Full-Lace-Hair-System-for-Men-5-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/OCT-French-Lace-Hair-System-with-NPU-Back-Sides-and-Double-Layered-Lace-Front-3-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/HS7-Full-French-Lace-Hair-System-for-Men-4-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
        {
          imgs: require("../../src/assets/ntc1009-mens-silk-top-toupee-2-300x300.jpg"),
          imging: require("../../src/assets/NW6378-Silk-Top-Hair-System-with-Clear-PU-around-2-300x300.jpg"),
          titles:
            "NTC1009 Silk Top Toupeewith Injected Skin allAround Wholesale",
        },
      ],
      navimgs: [
        {
          laceimg: require("../../src/assets/lace.png"),
          laceimg1: require("../../src/assets/lac.png"),
        },
      ],
      skinings: [
        {
          skining: require("../../src/assets/skin.png"),
          skining1: require("../../src/assets/ski.png"),
        },
      ],
      monoimgs: [
        {
          monoimg: require("../../src/assets/mono.png"),
          monoimg1: require("../../src/assets/mom.png"),
        },
      ],
      silkimgs: [
        {
          silkimg: require("../../src/assets/silk.png"),
          silkimg1: require("../../src/assets/sil.png"),
        },
      ],
      // HAIR EXTENSION & HAIR BULK
      hair: [
        {
          imgs: require("../../src/assets/index/20220624105906.png"),
          title: "Hair Extension",
          path: "/",
          conton: "Ordering Tools",
        },
        {
          imgs: require("../../src/assets/index/20220624105934.png"),
          title: "Bulk Hair",
          conton: "Tapes",
        },
        {
          imgs: require("../../src/assets/index/20220624105952.png"),
          title: "Pre-Bonded Hair",
          conton: "Others",
        },
      ],
      lvbox: [
        {
          imgs: require("../../src/assets/index/dasha@2x.png"),
          title: "Hair System Manufacturers",
          content:
            "Over 100 highly specialized senior wig-makers, craftsmen with decades ofexperienceConstantly supply with the highestquality standards",
        },
        {
          imgs: require("../../src/assets/index/dasha@2x.png"),
          title: "Hair System Manufacturers",
          content:
            "Over 100 highly specialized senior wig-makers, craftsmen with decades ofexperienceConstantly supply with the highestquality standards",
        },
        {
          imgs: require("../../src/assets/index/dasha@2x.png"),
          title: "Hair System Manufacturers",
          content:
            "Over 100 highly specialized senior wig-makers, craftsmen with decades ofexperienceConstantly supply with the highestquality standards",
        },
        {
          imgs: require("../../src/assets/index/dasha@2x.png"),
          title: "Hair System Manufacturers",
          content:
            "Over 100 highly specialized senior wig-makers, craftsmen with decades ofexperienceConstantly supply with the highestquality standards",
        },
        {
          imgs: require("../../src/assets/index/dasha@2x.png"),
          title: "Hair System Manufacturers",
          content:
            "Over 100 highly specialized senior wig-makers, craftsmen with decades ofexperienceConstantly supply with the highestquality standards",
        },
        {
          imgs: require("../../src/assets/index/dasha@2x.png"),
          title: "Hair System Manufacturers",
          content:
            "Over 100 highly specialized senior wig-makers, craftsmen with decades ofexperienceConstantly supply with the highestquality standards",
        },
      ],
      content: [
        {
          title: "MEN TOUPEES & HAIR REPLACEMENTS",
          img: require("../../src/assets/index/20220805141738.jpg"),
          content:
            "At TSINGTAO HAIR WIGSZONE Company, we offer a wide selection of toupees both in stock and custom made. Our wholesale toupees are manufactured with the finest real human hair following the strictest quality standard to provide high-quality, great-looking human hair toupees.In our hairpiece factory, every single item passes through several rounds of quality inspections before they’re ready for sale. As consumer needs and expectations for hair toupees keep changing and growing, We never stops expanding our toupee production line. Based on trends and market research, TSINGTAO HAIR WIGSZONE continuously launches new base designs that appeal to your target consumers.Our large selection of wholesale toupees in our factory is designed to meet the needs of our customers and clients. We’ve taken down the barriers to expanding your business, with fast shipping and below market pricing. Compare our pricing to average market prices and you’ll find lower prices with our wholesale toupees, particularly when purchased in large quantities, allowing for greater profitability.  We work hard to supply you with quality products that are designed to help your business grow and be successful.",
          imgs: require("../../src/assets/index/0220805141915.jpg"),
          title2: "WOMAN WIGS & HAIR PIECES",
          content2:
            "As a respected woman wig and hair pieces manufacturer and wholesaler that’s dedicated to wholesale woman hair worldwide, China wig supplier TSINGTAO HAIR WIGSZONE takes pride in producing top-notch woman hair wigs for our partners. Our quality wigs product line includes full lace wigs, thin skin wigs, medical wigs, lace front wigs and 360 lace wigs etc…both in stock and custom made. At our wigs factory, we have a highly specialized and experienced manufacturing team that’s dedicated to creating every wig. Our custom wig team is passionate about producing unique wigs based on your requests. All wigs and toupees can be customized to your customers’ requirements in terms of base size, base design, hair length, hair type, hair density, hair color, hair curl, and knot bleaching. Give your discriminating clients their favorite hair wigs, one that exclusively belongs to them! Just let us know your idea, we will make it come true!",
        },
      ],
      WHOLESALEs: [
        {
          title: "WHOLESALE CUSTOMER FEEDBACK",
          p: "Hear voices and feedback from New Times Hair real customers.",
        },
      ],
      WHYING: [
        {
          title: "WHY WIGSZONE FOR YOUR HAIR SYSTEM?",
          content:
            "WigsZone specialize in hair systems that promise a realistic look, a natural hairline, high durability, and affordable prices. Our non-surgical hair replacement products are shipped to over 100 countries with wholesale prices and good after sale service. We do not play tricks but insist on offering premium quality and reasonable prices hair replacements to our customers around the world.In recent years, non-surgical hair replacement systems have emerged as a great alternative to invasive and painful surgical procedures and they now are gaining huge popularity among people struggling with hair loss. Our hair systems for men are the perfect choice if you want a full head of hair without undergoing painful surgery. WigsZone cares deeply about people battling hair loss whatever their circumstances and our collection of medical wigs reflect our commitment to hair recovery amongst people suffering from illness or medical conditions.Here are the reasons why WigsZone is favored by people all across the globe seeking non-surgical hair recovery: ",
        },
      ],
      why: [
        {
          conten:
            " Lardhair has bocon dosigning and shlppng nor- sungcal halr replacomantsystoms for owor a docodo. Wa spoo alzo in halr systam thatpromlse a  rodistic lk a nctural halrlina, hlgh durabt, andfttectatelo price Cur        nen sugit hci rpent P adualt are 8niped 10 Dver mn courien: m with        atrercke dfxurs F4 fiat tire buppnla we hxlve saco exas that        gu8ecdalboral savings!In necant years, non aungieel hair raplocemer        syatem have amanged as o grear alear nerive ta inwniva and painfud su        gicdl proceduneaand they now ane gaining huge populanty umang        poepleetrugling with hair boss Our hair Srstem for man ara the perfect       chaico if youwant o ful head of har withour undargaing painful surgery Loardhair cares deepy about paapie batt ing hair basswhatever        theircireumetanca and ou allefoan al medical wiga relle our wmmimer to        het recevery cmenge people ulerin hram lihres ar mecieeerdtineHane an        tha moson wty Landhair is favared by men al gcrosa the glolbe scckihng        non-aungibal halr recoveny",
        },
      ],
      whyimg: [
        {
          img: require("../../src/assets/index/faxing@2x.png"),
          title: "Non-Surgical Hair Replacamant",
        },
        {
          img: require("../../src/assets/index/jiandao@2x.png"),
          title: "l Heady Ta Wecr",
        },
        {
          img: require("../../src/assets/index/helijiage@2x.png"),
          title: "AHordab Fatory Pricea",
        },
        {
          img: require("../../src/assets/index/riqi@2x.png"),
          title: "30-Doy Monry Back Cuaranta",
        },
        {
          img: require("../../src/assets/index/fanan@2x.png"),
          title: "Basy To Order online",
        },
        {
          img: require("../../src/assets/index/anquan@2x.png"),
          title: "l Sale Onli Payment",
        },
        {
          img: require("../../src/assets/index/wuliu@2x.png"),
          title: "FedEx Fast worldwidaPra sipping",
        },
        {
          img: require("../../src/assets/index/yaoqinghan@2x.png"),
          title: "l Pralaslane Custamar Servle",
        },
        {
          img: require("../../src/assets/index/xunhuan@2x.png"),
          title: "Frequent orda statu updata",
        },
      ],
      listphoto: [
        {
          img: require("../../src/assets/index/20220624135929.png"),
          content:
            "All orders were in superb condition when they arrive and they came right on time. The delivery time still amazes me. You are a company of your word, and I plan to do much more business with you. By the way Thank You once again for delivering a great product!!!! I'll be placing another bulk order very soon!!!!",
          name: "TANJU SERTTAS",
          img2: require("../../src/assets/index/20220624140138.png"),
          content2:
            "I'm a happy customer of you guys and I have brought plenty of units from you in the past. You guys are very professional in wholesale business from production, logistic to customer service! Love your quality let me just start off by saying that! I plan on becoming a good partner soon. My business consists of a variety of sales women clothes and accessories i wanted to start a new venture and add wigs and virgin hair to my list. I believe this will be a great access to the community with what we have been going thru. If so would you be able to give me a wholesale price list of full lace wigs. Looking forward to here back from you soon! ",
          name2: "Michelle",
        },
        {
          img: require("../../src/assets/index/20220624140318.png"),
          content:
            "I ordered custom made hair replacements of special colors for my clients and received the parcel always earlier than estimated. I am very happy with the service. The quality of the hair is very very GOOD and has been made very well! Shipping was sooo fast. WIGSZONE DESERVES ALL THE BESTTTTT!!! ",
          name: "William Oliver",
          img2: require("../../src/assets/index/mones.jpg"),
          content2:
            "It has almost been three months since I ordered my first medical wig from Wigszone, and it is still in perfect condition! No shedding, No tangling, and the curl pattern is just as beautiful as the first time I put it on, even after repeat straightening/ flat ironning!!! I am very happy with choosing you and your wigs! ",
          name2: "Michelle",
        },
        {
          img: require("../../src/assets/index/20220624140602.png"),
          content:
            "I have run men’s and women’s hair medical replacement business for more than 10 years and the hair systems Linda sent to me are wonderful! They are some of the best systems I’ve ever cut in and they are just perfect! We will definitely place more orders and continue business with WigsZone! ",
          name: "Martin Charles",
          img2: require("../../src/assets/index/0220624140657.png"),
          content2:
            "It has almost been three months since I ordered my first 360 lace wig from Tsingtao Hair Wigszone, and it is still in perfect condition! No shedding, No tangling, and the hair is just as beautiful as the first time I put it on, even after repeat straightening/ flat ironning!!! Their fast delivery time still amazes me! I received my wig in only two days!!! Their customer service has been excellent. I will soon be back to that site make another purchase!",
          name2: "Elisa ",
        },
      ],
      WHY: [
        { li: " Get the best wholesale prices" },
        { li: "Special offer for sample orders" },
        { li: "Access to product experts" },
      ],
      displist: [
        {
          p1: "Join us as an exclusive wholesaler",
          p2: "Why join?",
          p3: "Get the best wholesale prices",
          p4: "Special offer for sample orders",
          p5: "Access to product expert",
          button: " Apply now",
        },
      ],
      Hairicon: [
        {
          img: require("../assets/logoimgs/mans.png"),
          name: "Non-surgical Hair Replacement",
        },
        {
          img: require("../assets/logoimgs/kacha.png"),
          name: "Ready to Wear",
        },
        {
          img: require("../assets/logoimgs/money.png"),
          name: "Affordable Factory Prices",
        },
        {
          img: require("../assets/logoimgs/day.png"),
          name: "30-Day Money Back Guarantee",
        },
        {
          img: require("../assets/logoimgs/day.png"),
          name: "Easy To order online",
        },
        {
          img: require("../assets/logoimgs/dangers.png"),
          name: "Safe Online Payment",
        },
        {
          img: require("../assets/logoimgs/xin.png"),
          name: "Fast Worldwide Free Shipping",
        },
        {
          img: require("../assets/logoimgs/xin.png"),
          name: "Professional Customer service",
        },
        {
          img: require("../assets/logoimgs/zhuan.png"),
          name: "Frequent Order Status Updates",
        },
      ],
      isRotate: false,
      changeshow: "show more",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        currentTime: "00:00",
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: require("../assets/index/WeChat_20220809151657.mp4"), //本地视频使用方式，本地图片引入亦如此
            // src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",//视频地址
          },
        ],
        poster: this.url, //你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      //一开始视频观看时长，可用于记录视频播放的时间，下一次直接从这个时间开始播放
      gklog: "",
    };
  },
  created() {
    this.country = this.$store.state.countryJson;
    // function but(){
    //   document.getElementsByName("but").style.color="#00AC86"
    // }
    let isRotate = false;
    // console.log("56655");

    function handler() {
      let oDiv = document.querySelector(".card-face");
      // let oDivChild2 = document.querySelector('.card-face-back');
      // console.log(oDiv);
      isRotate = !isRotate;
      if (isRotate) {
        oDiv.setAttribute("class", "card-face card-rotate");
        //    oDivChild2.setAttribute('class', 'card-face-back card-face-back-backface-visble')
      } else {
        oDiv.setAttribute("class", "card-face");
        //    oDivChild2.setAttribute('class', 'card-face-back card-face-back-backface-hidden')
      }
    }
  },
  methods: {
    showorless() {
      this.isActive = !this.isActive;
      //  this.isActive  = true
      if (this.isActive == this.isActive) {
        this.changeshow = "show Less";
      } else if (this.isActive != this.isActive) {
        this.changeshow = "show more";
      }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    toTops() {
      document.documentElement.scrollTop = 0;
    },
    gos(index) {
      // console.log(index);
      if (index == 0) {
        this.$router.push({
          path: "../Orderguide",
        });
      }
    },
    godetail(index) {
      if (index == 0) {
        this.$router.push({
          path: "/KIND",
        });
      }
      if (index == 1) {
        this.$router.push({
          path: "/Toupees",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/WIGSZONE",
        });
      }
    },
    // gethelp提交按钮
    gethelp() {},
    checkoutType(index) {
      // console.log(index);
      this.tabs = index;
      this.typePopup = false;
      this.type_txt = this.typeList[index].title;
    },
    views(index) {
      // console.log(index);
      if (index == 0) {
        this.$router.push({
          path: "/Viewdetail",
        });
      }
      if (index == 1) {
        this.$router.push({
          path: "/Viewdetail",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/Viewdetail",
        });
      }
    },
    submit() {},
    submitss() {},
    showMore() {
      this.showBtn = false;
    },
    but(index) {
      // console.log(index, "111");
      this.tabs = index;
    },
    btn(index) {
      // console.log(index, "111");
      this.tab = index;
      if (this.lay_type == 0) {
        this.lay_type = 1;
      } else {
        this.lay_type = 0;
      }
      // if (this.lay_types == 0) {
      //   this.lay_types = 1;
      // } else {
      //   this.lay_types = 0;
      // }
      // if (this.lay_type3 == 0) {
      //   this.lay_type3 = 1;
      // } else {
      //   this.lay_type3 = 0;
      // }
      // if (this.lay_type4 == 0) {
      //   this.lay_type4 = 1;
      // } else {
      //   this.lay_type4 = 0;
      // }
    },

    //视频快进
    // goFast() {    //   this.gklog += 5;
    //   // console.log(" 快进", this.gklog,this.$refs.videoPlayer);
    //    this.$refs.videoPlayer.player.currentTime(this.gklog)
    // },
    // //视频后退
    // goBack() {
    //   this.gklog -= 5;    //   // console.log(" 后退", this.gklog,this.$refs.videoPlayer);
    //    this.$refs.videoPlayer.player.currentTime(this.gklog);
    // },
    /* 设置视频进度 */
    playerReadied(player) {
      player.currentTime(this.gklog);
      // console.log("视频初始时长",this.gklog)
    },
    onPlayerTimeupdate(player) {
      this.gklog = player.cache_.currentTime; //获取当前播放时间
      // console.log(" onPlayerTimeupdate!", this.gklog);
    },
  },
  watch: {
    gklog: function () {},
  },
};
</script>
<style lang="less" scoped>
    .video-js .vjs-tech {
        height: 660px;
      }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
*{padding: 0;margin: 0;}
.pcPRODUCT{
width: 100%; background-color: #fff; text-align: center;
  .upbox{
    width: 80%; margin: 0 auto; padding: 40px 0
  }
}
.phonePRODUCT{
  display: none;
  .upbox{
    width: 95%; margin: 0 auto; padding: 10px 0;
    text-align: center;
  }
}
 .pcHAIR{
  .HairExtensions{
  width: 60%;
  margin:0 auto;
  padding: 40px 0;
 

  .HairExtensions_harr{
    margin-top: 40px;
    img{
       width: 100%;
       height: 350px;
    }
  }
}
 }
 .phoneHAIR{
  display: none
 }
.phonestyle{
  display: none;
}
#carouselExampleIntervalss{
  display: none;
}
.bananan{
 height: 600px;
  width: 100%
}
 .container{
  width: 95%;
  margin: 10px auto;
 }
.btn-primary{
  border: none;
  background: none;
}
.posit{
  // position: relative;
  button{
    position: absolute;
    bottom:15px;
    left:45%;
  }
}
.posit1{
  button{
  background-color: #566987;
  border: 1px solid #566987;
  color: #fff;
  padding: 10px 3%;
  font-size: 22px;
  }
  button:hover{
    border: 1px solid #F39D36;
    background-color: #F39D36;
  }
}
.posit2{
  button{
  background-color: #F39D36;
  border: 1px solid #F39D36;
  color: #fff;
  padding: 10px 3%;
  font-size: 22px;
    bottom:190px;
    left:38.5%;  }
  button:hover{
  border: 1px solid #566987;
    background-color: #566987;
  }
}
.divbox2{
  display: none;
}
  .CONTACTs {
      display: none;
    }
    .CONTACT {
      text-align: center;

      padding: 50px 0;
      width: 80%;
      margin: 0 auto;
      h2 {
        font-weight: 600;
      }
      .inputs {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        input {
          width: 45%;
          height: 50px;
          margin: 10px 0;
          border: none;
          border: 1px solid #cfd5db;
        }
      }
      textarea {
        border: none;
        border: 1px solid #cfd5db;
        width: 95%;
        height: 200px;
      }
      button {
        margin-top: 30px;
        background-color: #304e6a;
        border: 1px solid #304e6a;
        padding: 10px 1.5%;
        border-radius: 5px;
        color: #fff;
      }
      button:hover {
        background-color: #f9bd5a;
        border: 1px solid #f9bd5a;
      }
    }
.typeBox {
  display: flex;
  flex-wrap: wrap; 
  box-sizing: border-box;
}
.type_item {
  width: calc(calc(100% - 30px) / 3);
  // height: 50px;
  background: #fff;
  color: #333;
  font-size: 13px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center; 
}
.WHOLESALE2{
  display: none;
}
 .WHOLESALE {
      width: 100%;
      text-align: center;
      .el-carousel__item h3 {
        // color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 600px;
        margin: 0;
      }
      .alwasing {
        width: 90%;  
       margin-left:5%;
       padding-top: 50px  ;

        display: flex;
        justify-content: space-around;
        .always {
          width: 48%;
          margin: 0 auto;
          text-align: center;
          .always_box {
            img {
              width: 95%;
            }
            p {
              color: #000;
              // width: 50%;
              z-index: 999;
              text-align: left;
            }
          }
        }
      }
    }
    
    .el-carousel__container{
      .el-carousel__arrow--left{
        margin-top: 30px;
      }
        .el-carousel__arrow--right{
        padding-top: 30px;
      }
    }
.modal-header{
   color: #fff;
    height: 270px;
  width: 100%;
  background: url("../../src/assets/index/20220624115343.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.modal-headers{
   color: #fff;
   height: 250px;
  width: 100%;
  background: url("../../src/assets/index/20220624091530.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
    text-align: center;
  h4{
    padding-top: 40px;
  }
}
.modal-dialog{
  max-width:900px;
  margin:0 auto;
}
.WHYS{
  background-color: #fff;
  margin: 0;
  height: 980px;
  width: 100%;
  background: url("../../src/assets/index/0220805152021.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding:100px 0; 
  color:#012340;
  margin:0 auto;text-align:center;
 
  li{
    font-size: 22px;
    line-height: 60px;
    list-style: none;
  }
  button{
    background-color:#012340 ;
    color: #fff;
    padding:7px 3%;
    border: none;
    border: 1px solid #012340;
    border-radius: 20px;
  }
  button:hover{
    background-color:#EAC203 ;
     border: none;
    border: 1px solid #EAC203;
  }
  .WHYScontent{
    max-width: 1280px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    h2{
      padding: 30px 0;
    padding-top:60px ;

    }
    p{
      line-height: 30px;
    }
  }
  .Tsingboxdis{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
    width: 70%;
    color: #fff;
    margin: 0px auto; 
    margin-block: 70px;
   .Tsingbox2{
    width: 30%;
    display: flex;
    justify-content: left;
    margin: 10px 0;
    img{
      width:13%; 
    }
    p{
      padding-top:9px;
      padding-left:3%;
    }
   }
  }
}

  .top {
    img {
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 50px;
    }
  }
  .tops{display: none;}
.asd{
    position: relative;

}
.asd:hover{
   .optic{
  display: block;

   }
}
.optic{
  background-color:#ccc;
  opacity: 0.7;
  width: 99%;
  height:60px;
  line-height:60px;
  position: absolute;
  bottom:20px;
  left: 0;
  display: none;
  color:#fff
}
.HairExtensions{
  background-color: #fff;
  width: 100%; 
  padding:50px 0;
  text-align: center;

  .HairExten{
    width: 80%;
    margin: 0 auto;
    div{
      img{
        width: 99%;
      }
    }
  }
}
 
.home{
  position: relative
}
.pos_fixed{
  // background-color: #fff;
 img{
   	position:fixed;
	top:0px;
  z-index:-1;
  //  bottom:600px;
  left: 0;
  width: 100%;
  height:900px;
  // margin-bottom: 100px;
 }
}
.why{
 
  color: #fff;

 
  h2{
    padding-top: 100px;
  }
  p{
    width: 50%;
    margin:20px auto
  }
}
.WHOLESALEleft{

  width:46%;
  background-color:#fff;
  padding:40px 1%;
  text-align: left;
  
  img{
    width:90%;
    height: 150px;
  }
  p{
    padding-top: 20px;
  }
}
#Explore{
  .card-header{
    
    padding: 5px 2%;
  }
  text-align: center;
  background-color: #fff;
  .Explore_p{
    font-size: 30px;
    font-weight: bold;
    padding:30px 0;
    width: 100%;
  }

  .Explore_box1{
   height: 650px;
  width: 100%;
  background: url(../../src/assets/index/banner_7@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
   .accordion{
    width: 40%;
    margin-left: 20%;
  }

   .btns{
    // width: 22%;
    margin-right: 20%;
    background-color:#012340;
    border:1px solid #012340;
    color: #fff;
    padding:8px 3%;
    border-radius:25px 25px;
    margin-top: 20px;
  }
  .btns:hover{
      background-color:#F29D35;
    border:1px solid #F29D35; 
  }
  .p{
         font-weight: bold;
            width: 50%;
            padding-left: 20%;
            padding-top: 110px;
  }
  }
   .Explore_box2{
     
   height: 650px;
  width: 100%;
  background: url(../../src/assets/index/banner_8@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  
  .zhedie2{
  width: 60%;
  background-color: #2E435C;
   opacity: 0.7;
   height: 650px;
   margin-left: 40%;
 
  .accordion{
    width: 80%;
    padding-left:19% ;
    padding-top:50px ;
  }
  
}
   .btns{
    // width: 22%;
    margin-right:7%;
    background-color:#EAC203;
    border:1px solid #EAC203;
     padding: 8px 3%;
    border-radius:25px 25px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
   .btns:hover{
      background-color:#fff;
    border:1px solid #fff; 
    color: #000;
  }
  }
  .Explore_box3{
        .p{
          font-weight: bold; padding-right: 35%; padding-top: 110px
        }
   height: 650px;
  width: 100%;
  background: url(../../src/assets/index/banner_9@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
    .accordion{
      width: 40%;
   margin-left: 20%;
  }
   .btns{
    // width: 22%;
    margin-right:22%;
    background-color:#012340;
    border:1px solid #012340;
    padding: 8px 3%;
    color:#fff;
    border-radius:25px 25px;
    margin-top: 50px;
  }
   .btns:hover{
      background-color:#F29D35;
    border:1px solid #F29D35; 
  }
  }
}
#Explores{display: none;}
.headhair{
  display: flex; justify-content: space-around; flex-wrap: wrap
}
 .Extensionspc{ 
    display: flex; justify-content: space-around
  }
.moblelv{
  display: none;
}
.pclv{
         display: flex;
          justify-content: space-around;
          margin: 40px 0;
          flex-wrap: wrap; 

           .card-face {
    
            /* 如果没有这句，翻转过来会不显示 */
            transform-style: preserve-3d; 
            transform-origin: left center;
            transition: transform 1s;

        }
        .card-face-back {
            border: 1px solid blue; 
            transform: rotateY(180deg);
            backface-visibility: hidden;
        }
        .card-rotate {
            transform: translateX(100%) rotateY(-180deg);
        }
        .card-face-back-backface-visble {
            backface-visibility: visible;
        }
        .card-face-back-backface-hidden {
            backface-visibility: hidden;
        }
 
}
.h2moble{
  display: none;
}
.Extensionsmoble{
  display: none;
}
a{
  text-decoration: none;
  color: #000;
}
a:hover{
  text-decoration: none;
  
}
.upboxbtn{
background-color:#012340;
padding:6px 5%;
color:#fff;
border:1px solid #012340; 
margin-left:30%;
margin-top: 10px;
border-radius:25px 25px;
a{
  text-decoration: none;
  color: #fff;
}
}
.upboxbtn:hover{
background-color:#F29D35;
border:1px solid #F29D35; 

}
.jionbg{
  position: fixed;
  z-index: -1;
  top: 200px;
  width: 100%;
  background: url(../../src/assets/index/banner_4@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat; 
}
.phonedisp{
  display: none;
}
.disp{
    color: #012340;
    font-size: 29px;
    text-align: center;
    font-weight: 600;
  height: 500px;
   button{
   background-color:#012340;
   border: 1px solid #012340;
   border-radius: 30px;
   color:#fff;
   font-size: 20px;
   padding: 5px 2%; 
   margin-top: 40px;
 }
 button:hover{
     background-color:#EAC203;
   border: 1px solid #EAC203;
 }
}
.tabone {
  display: flex;
  justify-content: space-around;
  width:70%;
  margin: 0 auto;
}
.womanimgs:hover {
  transform: scale(1.1); //放大图片
  cursor: pointer; //变小手
  transition: all 0.7s;
}
.btning:hover {
  cursor: pointer; //变小手
}
.womanimgs {
  position: relative;
  padding-top: 50px;
  img {
    width: 100%;
    height: 443px;
  }
  .tabs_one {
    position: absolute;
    bottom: 0;
    background-color: #000;
    opacity: 0.5;
    color: #fff;
    width: 100%;
    p{
      padding-bottom: 23px;
    }

  }
    button{
      background-color:#FF0082;
      color:#fff;padding:5px 5%;
      position: absolute;
      border: 1px solid #FF0082;
      bottom:5px;
      left:7px;
    }
    button:hover{
      transform: scale(1.1); //放大 
    }
}
.womensmenu2{
  display: none; 
}
.womensmenu {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  span {
    width: 170px;
    margin-top: 30px; 
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    background-color: #fff;
    color: #FF0082;
    border: 1px soild #fff;
    border-radius: 8px 8px;
  } 
 .typeActive {
    background-color: #FF0082;   
        color: #FFFFFF;
  
}
  span:hover {
    background-color: #FF0082;
    color: #fff;
  }
 
}
.bgimgbox {
  height:600px;
  width: 100%;
  background: url("../../src/assets/index/0220805114423.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title {
    // color: #091A41;
    color: #fff;
    font-weight:900;
    text-align: center;
    font-size: 35px;
    padding-top: 100px;
    line-height: 50px;
  }
 
  .bgimgbox_img {
    width: 43%;
    margin:80px auto;
    display: flex;
    justify-content: space-around;
    text-align: center;
    div{
      width:30%;
      text-align: center;
         img {
          border-radius:50%;
          width:45%;
          // height: 108px;
        }
        p{
          color: #fff;
          font-size: 22px;
    font-weight: 600;

        }
    }
  
  }
}
.bgimgbox2 {
  text-align: center; 
  width: 100%;
  background: url(../../src/assets/index/20805135609.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .mobshow {
    display: none;
  }
  .pcshow{
    h3{
        color: #FF0082;  padding-top:40px;
    }
     p{
        color: #FF0082;  padding-top: 20px;
        width: 60%; margin: 0 auto; 
      }
  }
}
#carouselExampleControls {
  display: none;
}
.divbox {
  width:90%;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .allimg {
    width: 25%;
  }
  .allimg:hover {
    .imgs {
      display: none;
    }
    .imging {
      display: block;
      
    }
  }
  .imging {
    display: none;
  }
  img {
    width: 85%;
    // height: 280px;
    // display: block;
  }
}
.menToupee {
  background-color: #fff;
  text-align: center;
  h2 {
    padding: 50px 0;
  }
}
.menu {
  display: flex;
  justify-content: space-around;
  width: 95%;
  margin: 0 auto;
.laceimg { 
    width:100%; 
    height:90px
  }
  .laceimg1 {
    display: none;
    width:100%;
    height:90px

  }
  .navimgs:hover {
    .laceimg {
      display: none;
    }
    .laceimg1 {
      display: block;
    }
  } 
.skining{
      width:100%;
    height:90px
}
  .skining1 {
    display: none; 
          width:100%;
    height:90px
  }
  
  .skinings:hover {
    .skining {
      display: none;
    }
    .skining1 {
      display: block;
    }
  }
  .monoimg{
        width:100%;
    height:90px
  }
  .monoimg1 {
          width:100%;
    height:90px;
    display: none;
  }
  .monoimgs:hover {
    .monoimg {
      display: none;
    }
    .monoimg1 {
      display: block;
    }
  }
  .silkimg{
          width:100%;
    height:90px
  }
  .silkimg1 {
    display: none;
          width:100%;
    height:90px
  }
  .silkimgs:hover {
    .silkimg {
      display: none;
    }
    .silkimg1 {
      display: block;
    }
  }
}
.menu span {
  // color: #444950;
  font-size: 12px;
  line-height: 24px;
  // background: #f5f6f7;
  padding: 0 6px;
  display: block;
  cursor: pointer;
}
.show {
  color: #fff !important;
  // background: #1877f2 !important;
}
.aboutNews {
  text-align: center;
  background-color: #fff;
      padding-top: 60px;
  max-width: 1280px;
  margin: 0 auto;
  // height: 700px;
    .containers{
      margin: 0 auto;
      padding-top: 50px;
      width: 95%; 
      .row{
        display: flex;
        justify-content: space-around;
      }
    }
  h2 {
    margin-bottom: 20px;
  }
  h5 {
    margin-bottom: 20px;
  }
  .cont { 
    width: 50%;
    height:370px;
    border-right: 10px solid #091a41;
    border-bottom: 10px solid #091a41;
    background: rgba(255, 255, 255, 0.39);
    opacity: 1;
    font-size: 13px;
    .cont-t {
      // padding-top: 20px;
      width: 100%;
      text-align: left;
    }
    .cont-btn {
      // display: flex;
      // justify-content: space-around;
      margin: 5px 0;
      width: 100%;
      .btn {
        font-size: 13px;
        background-color: #091a41;
        border: 1px solid #091a41;
        padding: 6px 5%;
      }
      .btn-outline-warning {
        color: #fff;
      }
      .btn:hover {
        color: #000;
        background-color: #ffc107;
        border: 1px solid #ffc107;
        color: #fff;
        padding: 6px 5%;
      }
    }
  }
  .conts {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 478px;
    background: url("../assets/index/bck.png");
  
    .video:hover {
      transform: scale(1.1);
      // animation: name duration timing-function delay iteration-count direction fill-mode;
    }
  }
    
}
// #vjs_video_3{
//         height: 660px;

// }
// .video-player video-player vjs-custom-skin{
//         height:660px;

// }

.video {
  // padding-top: 20px;
      width: 45%;
      height:400px;
       padding-top:20px;

      // border: 8px solid #ffc107;
      border-radius: 50% ;
  
    }
.menToupee {

  background-color: #fff;
  margin:  0px auto;
  // padding-top:210px ;
  width: 80%;
  .menNav {
    height: 60px;
    border-bottom: 1px solid #d1dfff;
  }
}
.words {
  max-height: 40px;
  color: #666;
  font-size: 14px;
  position: relative;
}
.box {
  color: #fff;
}
.box::before,
.box::after {
  content: attr(content);
  overflow: hidden;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  color: #666;
  line-height: 20px;
}
.box::before {
  max-height: 20px;
  z-index: 99;
  background: #fff;
}
.box::after {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-line-clamp: 2;
  text-indent: -4em;
  padding-right: 4em;
}
.box .btning {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
  color: #FF0082;
  line-height: 20px;
} 

.lvbox{
   width: 30%;
   margin: 20px 0;
   img{
     width: 30%;
   }
}
 .headhairmoble{
    display: none;
  }
 .FAQ{
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 50px 0 ;
  .FAQ_dis{
  max-width: 1280px;
  margin:0 auto;
  display: flex;
  justify-content: space-around;
    div{
    width: 30%;
    text-align: left;
    img{
      width:80%
    }
  }
  .FAQMARE{
    font-size: 20px;
  }
  }


 }
@media screen and (max-width: 1160px) {
}
// @media screen and (max-width: 820px) iPad Air
// @media screen and (max-width: 768px) iPad Mini

@media screen and (max-width: 390px) {
  .pcPRODUCT{
    display: none;
  }
  .phonePRODUCT{
    display: block;
width: 100%; background-color: #fff; text-align: center

  }
  .disp{
    display: none;
  }
  .phonedisp{
    display: block;
    color: #012340;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
     height: 400px;
   button{
   background-color:#012340;
   border: 1px solid #012340;
   border-radius: 30px;
   color:#fff;
   font-size:13px;
   padding: 5px 3%; 
   margin-top: 40px;
 }
 button:hover{
     background-color:#EAC203;
   border: 1px solid #EAC203;
 }
  }
.phoneHAIR{
  display: block;
   .HairExtensions{
  width: 80%;
  margin:0 auto;
  padding: 40px 0;
 

  .HairExtensions_harr{
    margin-top: 40px;
    img{
       width: 100%; 
    }
  }
}
}
.pcHAIR{
  display: none;
}
 .pcstyle{
display: none;
}
.phonestyle{
  display: block;
  width: 90%;
  margin:0 auto;
}
.WHYS{
  color:#012340;width:100%;height:350px;margin:0 auto; 
  padding-top:50px;
  h2{
    font-size: 22px;
  } 
  ul{
    width: 100%;
    text-align:center;
  }
  li{
    font-size: 20px;
    list-style: none; 
    line-height: 30px;
  }
  button{
    background-color:#012340 ;
    color: #fff;
    padding:7px 3%;
    border: none;
    border: 1px solid #012340;
    border-radius: 20px;
  }
  button:hover{
    background-color:#EAC203 ;
     border: none;
    border: 1px solid #EAC203;
  }
} 
#carouselExampleIntervalss{
  width: 100%;
  display: block;
}
#carouselExampleInterval{
  display: none;
}
.posit{
  position: relative; 
}
 
 .posit1 {
 button{
  background-color: #566987;
  border: 1px solid #566987;
  color: #fff;
   position: absolute;
   bottom:15px;
   left:45%;
  padding: 5px 3%;
  font-size: 13px;
  }
  button:hover{
    border: 1px solid #F39D36;
    background-color: #F39D36;
  } 
 }
 .posit2{
  button{
  background-color: #F39D36;
  border: 1px solid #F39D36;
  color: #fff;
  padding: 5px 3%;
  font-size: 12px;
   position: absolute;
  bottom:15px; 
  left:38.5%;  }
  button:hover{
  border: 1px solid #566987;
    background-color: #566987;
  }
}
  .divbox{
    display: none;
  }
  .divbox2 {
    display: block;
  width:90%;
  margin: 0 auto;
  padding: 100px 0;
  .allimg {
    width: 100%;
  }
  .allimg:hover {
    .imgs {
      display: none;
    }
    .imging {
      display: block;
      
    }
  }
  .imging {
    display: none;
  }
  img {
    width: 85%;
    // height: 280px;
    // display: block;
  }
}
  // .pos_fixed{
  //   display: none;
  // }
  .menToupee {

  background-color: #fff;
  margin:0px auto;
  padding-top:450px ;
  width: 80%;
  .menNav {
    height: 60px;
    border-bottom: 1px solid #d1dfff;
  }
}
 
  .headhair{
    display: none;
  }
  .headhairmoble{
    display: block;
    width: 100%;
    text-align: center;
  }
  .asd:hover{
   .optic{
  display: block;

   }
}
  .pclv{display: none;}

  .moblelv{display: block;}
  .h2moble{
    display: block;
  }
   .h2pc{
    display: none;
  }
  .Extensionspc{
    display: none; 
  }
  .Extensionsmoble{
    display: block;
  }
.womensmenu2{
  display: block;
   width: 80%;
   margin: 20px auto;
  p {
    width: 250px;
    margin-top: 0px;
    padding: 10px 0%;
    cursor: pointer;
    background-color: #fff;
    color: #FF0082;
    border: 1px soild #fff;
    border-radius: 8px 8px;
  }
    div{
    margin:10px 0
  }
  p:hover {
    background-color: #FF0082;
    color: #fff;
  }
  p:visited {
    background-color: #FF0082;
    color: #fff;
  }
}
  .womensmenu{
    display: none;
  }
  .bgimgbox {
    height: 450px;
    width: 100%;
    background: url(../../src/assets/index/banner_9@2x.png);
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    .title {
      color: #fff;
      text-align: center;
      font-size: 15px;
      padding-top: 100px;
    }
    img {
      width: 25%;
      height: 45px;
    }
    .bgimgbox_img {
      width: 80%;
      margin: 10px auto;
      display: flex;
      justify-content: space-around;
      img{
        width:20%;
        height: 60px;
      }
    }
  }
  .bgimgbox2 { 
    width: 100%;
    background: url(../../src/assets/index/banner_2@2x.png);
    // background-size: 100% 100%;
    background-repeat: no-repeat;

    .mobshow {
      display: block;
    }
    .pcshow {
      display: none;
      p{
        color: #FF0082; font-size: 45px; padding-top: 150px
      }
    }
  }
  .womensmenu {
    width: 80%;
    margin: 0 auto;
    // display: flex;
  }

  #carouselExampleControls {
    display: block;
    margin-top: 20px;
  }
  .menu {
    display: none;
  }
 
  .aboutNews {
  text-align: center;
width:100%;
    // margin: 60px 0 30px;
    // height: 1000px;
    h2 {
      margin-bottom: 20px;
      font-size: 26px;
    }
    h5 {
      margin-bottom: 20px;
      font-size: 14px;
    }

    .cont {
      // width: 50%;
      // height: 478px;
      border-right: 0px solid #091a41;
      border-bottom: 0px solid #091a41;
      background: rgba(255, 255, 255, 0.39);
      opacity: 1;
      .cont-t {
        padding-top: 10px;
        width: 100%;
        text-align: left;
      }
      .cont-btn {
        // display: flex;
        // justify-content: space-around;
        margin: 10px 0;
        width: 100%;
        .btn {
          font-size: 12px;
          padding: 3px 2%;
          background-color: #091a41;
        }
        .btn-outline-warning {
          color: #fff;
        }
        .btn:hover {
          color: #000;
          padding: 5px 2%;

          background-color: #ffc107;
        }
      }
    }
    .conts {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 278px;
      background: url("../assets/index/bck.png");
      .video {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 80px;
        height: 130px;
        // border: 8px solid #ffc107;
        border-radius: 50%;
        // background: #ffc107;
        // .video-s {
        //   width: 0px;
        //   height: 0px;
        //   border: 20px solid #ffc107;
        //   border-left-color: #ffc107;
        //   border-right-color: transparent;
        //   border-top-color: transparent;
        //   border-bottom-color: transparent;
        //   border-radius: 5px;
        //   // background: #ffc107;
        // }
      }
      .video:hover {
        transform: scale(1.1);
        // animation: name duration timing-function delay iteration-count direction fill-mode;
      }
    }
  }
 
 .womanimgs:hover {
  transform: none //放大图片
  // cursor: pointer; //变小手
  // transition: all 0.6s;
}
#Explore{display: none;}
#Explores{
  display: block;
  text-align: center;
  background-color: #fff;
  .Explore_p{
    font-size: 25px;
    font-weight: bold;
    padding:30px 0;
    width: 100%;
  }

  .Explore_box1{
  //  height: 650px;
  width:98%;
  margin:0 auto;
  background: url(../../src/assets/index/banner_7@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
   .accordion{
    width: 100%;
    // margin-left: 20%;
  }
   .btns{
    // width: 22%;
    margin-right:2%;
    
    background-color:#EAC203;
    border:1px solid #EAC203;
    border-radius:22px 22px;
    margin: 20px 0;
    padding: 7px 2%;
  }
  .p{
         font-weight: bold;
            width: 100%; 
            padding-top: 50px;
  }
  }
   .Explore_box2{
    text-align: center;
  //  height: 650px;
  width: 100%;
  background: url(../../src/assets/index/banner_8@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 50px 0;

  .zhedie2{
  width: 98%;
  margin: 10px auto;
  background-color: #2E435C;
    text-align: center;
   opacity: 1;
  //  height: 650px; 
 
  .accordion{
    width: 98%;
    padding-left:2% ;
    padding-top:50px ;
  }
    p{
  font-weight: bold;
    width: 100%; 
    padding-top: 50px;
  }
}
   .btns{
    // width: 22%;
    margin-right:2%;
    background-color:#fff;
    border:1px solid #fff; 
      margin: 20px 0;

    margin-bottom: 20px;
     border-radius:22px 22px; 
    padding: 6px 2%;
  }
  }
  .Explore_box3{
          p{
         font-weight: bold;
            width: 100%; 
            padding-top: 50px;
  }
  //  height: 650px;
  width: 100%;
  background: url(../../src/assets/index/banner_9@2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
    .accordion{
    
      width: 98%;
      margin: 0 auto;
  //  margin-left: 20%;
  }
   .btns{
    // width: 22%;
    margin-right:2%;
    background-color:#012340;
    border:1px solid #012340; 
    border-radius:22px 22px; 
    margin: 20px 0;
    padding: 6px 2%;
    a{
           color: #fff;
    }
  }
  .btns:hover{
        background-color:#F29D35;
      border:1px solid #F29D35;
  }
  }
}
  .top {
    display: none;
  }

  .tops {
    display: block;
    img {
      width: 15%;
      height: 50px;
      position: fixed;
      z-index: 999;
      bottom: 50px;
      right: 10px;
    }
  }
  .WHOLESALE{
    display: none;
    h2{
      font-size: 20px;
    }
  }
  .WHOLESALE2{
    display: block;
        width: 100%;
      text-align: center;
      .el-carousel__item h3 {
        // color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 600px;
        margin: 0;
      }
      .alwasing {
        width: 98%; 
        .always {
          width: 95%;
          margin: 0 auto;
          text-align: center;
          .always_box {
            img {
              width: 95%;
            }
            p {
              color: #000;
              width: 95%;  
              z-index: 999;
              margin-left:4%;
              text-align: left;
            }
          }
        }
      }
     h2{
      font-size: 20px;
    }

    
  }
 
   
}
</style>
