import axios from "axios"; //原生的axios
import { Message } from 'element-ui';
//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
//创建一个单例
const http = axios.create({
    baseURL: 'http://www.trustwigs.com/',
    timeout: 15000, //响应时间
    // headers:{"Content-Type":"application/json;charset=utf-8"},
})

//拦截器  -请求拦截
http.interceptors.request.use(config => {
    //部分接口需要token
    let token = localStorage.getItem('token');
    if (token) {
        config.headers.token = token;
        // config.headers ={
        // 'token':token
        // }
    }
    return config;
}, err => {
    // debugger
    return Promise.reject(err)
})

//拦截器  -响应拦截
http.interceptors.response.use(res => {
    // console.log(res, "coco")
    if (res && res.data && res.data.code && res.data.code === 1) {
        return Promise.resolve(res.data)
        //这里读者们可以根据服务器返回的数据去自行修改
    } else {
        Message.error({
            duration: 1500,
            message: res.data.msg,
        })
        return Promise.reject(res.data)

    }
}, err => {
    return Promise.reject(err)
});

//整体导出
export default http;