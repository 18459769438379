import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/about',
    name: 'About',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/About.vue')
},
{
    path: '/Men',
    name: 'Men',
    component: () =>
        import( /* webpackChunkName: "Men" */ '../views/Men/Men.vue')
},
{
    path: '/Women',
    name: 'Women',
    component: () =>
        import( /* webpackChunkName: "Men" */ '../views/Women/Women.vue')
},

{
    path: '/Newtimehair',
    name: 'Newtimehair',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Newtimehair.vue')
},
{
    path: '/MeetTeam',
    name: 'MeetTeam',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/MeetTeam.vue')
},
{
    path: '/Wholesale',
    name: 'Wholesale',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Wholesale.vue')
},
{
    path: '/Cases',
    name: 'Cases',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Cases.vue')
},
{
    path: '/Supplies',
    name: 'Supplies',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Supplies.vue')
},
{
    path: '/Contact',
    name: 'Contact',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Contact.vue')
},

{
    path: '/Stock',
    name: 'Stock',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Stock.vue')
},
{
    path: '/Custom',
    name: 'Custom',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Custom.vue')
},
{
    path: '/Lace',
    name: 'Lace',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Lace.vue')
},
{
    path: '/Skin',
    name: 'Skin',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Skin.vue')
},
{
    path: '/Mono',
    name: 'Mono',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Mono.vue')
},
{
    path: '/SilkTop',
    name: 'SilkTop',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/SilkTop.vue')
},
{
    path: '/Frontal',
    name: 'Frontal',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Frontal.vue')
},
{
    path: '/Crown',
    name: 'Crown',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Crown.vue')
},
{
    path: '/FullCap',
    name: 'FullCap',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/FullCap.vue')
},
{
    path: '/Afro',
    name: 'Afro',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Afro.vue')
},
{
    path: '/Repair',
    name: 'Repair',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Repair.vue')
},
{
    path: '/Duplidete',
    name: 'Duplidete',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Men/Duplidete.vue')
},
{
    path: '/Hairless',
    name: 'Hairless',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Hairless.vue')
},
{
    path: '/Intergration',
    name: 'Intergration',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Intergration.vue')
},
{
    path: '/Medical',
    name: 'Medical',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Medical.vue')
},
{
    path: '/Jeiws',
    name: 'Jeiws',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Jeiws.vue')
},
{
    path: '/Toppers',
    name: 'Toppers',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Toppers.vue')
},
{
    path: '/Human',
    name: 'Human',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Human.vue')
},
{
    path: '/Extensions',
    name: 'Extensions',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Extensions.vue')
},
{
    path: '/Closure',
    name: 'Closure',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Closure.vue')
},
{
    path: '/Bundle',
    name: 'Bundle',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Bundle.vue')
},
{
    path: '/Front',
    name: 'Front',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Women/Front.vue')
},
{
    path: '/AboutHair',
    name: 'AboutHair',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/AboutHair.vue')
},
{
    path: '/HairReview',
    name: 'HairReview',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/HairReview.vue')
},
{
    path: '/HairTechniques',
    name: 'HairTechniques',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/HairTechniques.vue')
},
{
    path: '/HoTraining',
    name: 'HoTraining',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/HoTraining.vue')
},
{
    path: '/Knowledge',
    name: 'Knowledge',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/Knowledge.vue')
},
{
    path: '/Maintenance',
    name: 'Maintenance',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/Maintenance.vue')
},
{
    path: '/NewHair',
    name: 'NewHair',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/NewHair.vue')
},
{
    path: '/OrderingGuide',
    name: 'OrderingGuide',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/OrderingGuide.vue')
},
{
    path: '/VideoGallery',
    name: 'VideoGallery',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Learning/VideoGallery.vue')
},
{
    path: '/Imgdetail',
    name: 'Imgdetail',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Imgdetail.vue')
},
{
    path: '/Lordhair',
    name: 'Lordhair',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Lordhair.vue')
},
{
    path: '/Orderguide',
    name: 'Orderguide',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Orderguide.vue')
},
{
    path: '/KIND',
    name: 'KIND',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/KIND.vue')
},
{
    path: '/Toupees',
    name: 'Toupees',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Toupees.vue')
},
{
    path: '/WIGSZONE',
    name: 'WIGSZONE',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/WIGSZONE.vue')
},
{
    path: '/MOREs',
    name: 'MOREs',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/MOREs.vue')
}, {
    path: '/Access',
    name: 'Access',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Access.vue')
},
{
    path: '/Help',
    name: 'Help',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Help.vue')
},
{
    path: '/Viewdetail',
    name: 'Viewdetail',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Viewdetail.vue')
},
{
    path: '/ceshi',
    name: 'ceshi',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/ceshi.vue')
},
{
    path: '/Attaching',
    name: 'Attaching',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views//Helps/Attaching.vue')
},
{
    path: '/helptemplate',
    name: 'helptemplate',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views//Helps/helptemplate.vue')
},
// 询盘 now
{
    path: '/Inquirynow',
    name: 'Inquirynow',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Inquirynow.vue')
},
// 加入我们询盘 now
{
    path: '/Joinnow',
    name: 'Joinnow',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/joinnow.vue')
},
// 产品列表
{
    path: '/product',
    name: 'product',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/product.vue')
},
// 新闻列表
{
    path: '/newsList',
    name: 'newsList',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/Helps/newsList.vue')
},
// 产品详情 订购
{
    path: '/productshow',
    name: 'productshow',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/productshow.vue')
},
// 用户中心
{
    path: '/usercenter',
    name: 'usercenter',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/usercenter.vue')
},
// 用户中心
{
    path: '/forgetpw',
    name: 'forgetpw',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/mycenter/forgetpw.vue')
},
// 用户中心  控制台
{
    path: '/account',
    name: 'account',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/mycenter/account.vue')
},
]

//*******以下为添加内容*******
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
//******以上为添加内容*********
const router = new VueRouter({
    routes,
    mode: "history"
})

export default router