import request from '@/router/request'

// 用户中心
// 注册
export function postRegisterApi(data) {
    return request({
        url: '/api/login/register',
        method: 'post',
        data
    })
}
// 登录
export function postLoginApi(data) {
    return request({
        url: '/api/login/login',
        method: 'post',
        data,
    })
}
// 忘记密码 提交账号绑定邮箱
export function postResetPasswordApi(data) {
    return request({
        url: '/api/login/resetPassword',
        method: 'post',
        data
    })
}
// 查询用户信息
export function getUserinfoApi(data) {
    return request({
        url: '/api/user/userinfo',
        method: 'post',
        data
    })
}
// 用户信息 编辑
export function postUserinfoEditApi(data) {
    return request({
        url: '/api/user/useredit',
        method: 'post',
        data
    })
}
// 修改密码
export function postUserResetPasswordApi(data) {
    return request({
        url: '/api/user/resetPassword',
        method: 'post',
        data
    })
}
// 用户地址 
export function postAddressApi(data) {
    return request({
        url: '/api/user/address',
        method: 'post',
        data
    })
}
// 编辑 用户地址 /api/user/addressedit 
export function postEditAddressApi(data) {
    return request({
        url: '/api/user/addressedit',
        method: 'post',
        data
    })
}
// 用户注销
export function postLogoutApi(data) {
    return request({
        url: '/api/user/cancel',
        method: 'post',
        data
    })
}
// 用户确认收货
export function postOrderFinishApi(data) {
    return request({
        url: '/api/user/orderreceive',
        method: 'post',
        data
    })
}
// 订单列表
export function postOrderListApi(data) {
    return request({
        url: '/api/user/order',
        method: 'post',
        data
    })
}
