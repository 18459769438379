<template>
  <div>
    <div class="foot">
      <h4 style="padding-top: 50px">Hey, let's keep in touch!</h4>
      <p>
        Be the first to get exclusive offers, in-stock updates, tips and more
      </p>
      <div>
        <input
          type="text"
          name="email"
          v-model="tEmail"
          placeholder="Your email address"
          style="
            padding: 5px 2%;
            background-color: #2a2a2a;
            border: 1px solid #2a2a2a;
            color: #fff;
          "
        />
        <button
          style="
            background-color: #f29d35;
            border: 1px solid #f29d35;
            padding: 5px 2%;
            color: #fff;
          "
          @click="getEmail"
        >
          Subscribe
        </button>
      </div>
      <div class="foot_div">
        <ul>
          <li style="font-size: 20px; fone-weight: bold; padding-bottom: 10px">
            COMPANY
          </li>
          <li
            v-for="(item, index) in lis1"
            :key="index"
            style="font-size: 16px; line-height: 26px"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul>
          <li style="font-size: 20px; fone-weight: bold; padding-bottom: 22px">
            WARRANTY
          </li>
          <li
            v-for="(item, index) in articleList1"
            :key="index"
            style="font-size: 16px; line-height: 26px"
            @click="actialClick(item.article_id)"
          >
            {{ item.article_title }}
          </li>
        </ul>
        <ul>
          <li style="font-size: 20px; fone-weight: bold; padding-bottom: 22px">
            HELP & SUPPORT
          </li>
          <li
            v-for="(item, index) in articleList2"
            :key="index"
            style="font-size: 16px; line-height: 26px"
            @click="actialClick(item.article_id)"
          >
            {{ item.article_title }}
          </li>
        </ul>
        <ul>
          <li style="font-size: 20px; fone-weight: bold; padding-bottom: 22px">
            BE OUR PARTNER
          </li>
          <li
            v-for="(item, index) in articleList4"
            :key="index"
            style="font-size: 16px; line-height: 26px"
            @click="actialClick(item.article_id)"
          >
            {{ item.article_title }}
          </li>
        </ul>
        <ul style="width: 25%">
          <li style="font-size: 20px; fone-weight: bold; padding-bottom: 22px">
            CONTACT
          </li>
          <li
            v-for="(item, index) in lis5"
            :key="index"
            style="font-size: 16px; line-height: 26px"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <p style="width: 31%; margin: 0 auto; padding: 30px 0">
        Wholesale human hair replacement system, toupee and wig manufactuer.o
        2022 New Times Hair. All rights reserved.
      </p>
    </div>
  </div>
</template>
<script>
// import getEmailApi from "../api/index.js";
import { getEmailApi, getArticleIndexApi, article } from "../api/index";
export default {
  name: "Footer",
  data() {
    return {
      lis1: [
        { name: "Hair Extensions & Bulk Hair" },
        { name: "Accessories" },
        { name: "Wholesale" },
        { name: "Contact" },
      ],
      lis5: [
        { name: " Mobile: +86-1509-2233-077" },
        { name: "WhatsApp:+86-1509-2233-077" },
        { name: "Email:info@wigszone.com" },
      ],
      tEmail: "",
      articleList1: [],
      articleList2: [],
      articleList4: [],
    };
  },
  created() {
    this.getThreeScool(1);
    this.getThreeScool(2);
    this.getThreeScool(4);
  },
  methods: {
    /**
     * 获取三个新闻分类
     * @size 获取文章个数
     * @category_id 文章分类ID
     */
    getThreeScool(id) {
      // debugger;
      let articleData = {};
      articleData.category_id = id;
      articleData.size = 7;
      articleData.page = 1;
      article(articleData).then((res) => {
        if (res && res.code && res.code == 1) {
          if (id == 1) {
            this.articleList1 = res.data;
          } else if (id == 2) {
            this.articleList2 = res.data;
          } else {
            this.articleList4 = res.data;
          }
        }
      });
    },
    getEmail() {
      const regEmail =
        /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!regEmail.test(this.tEmail)) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      // return;
      getEmailApi(this.tEmail).then((res) => {
        if (res && res.code && res.code == 1) {
          this.$alert("Subscribe to the success", "", {
            confirmButtonText: "确定",
          });
        }
      });
    },
    /**
     * 底部新闻列表 点击
     * @id 新闻id
     */
    actialClick(id) {
      if (this.$route.name.includes("Attaching")) {
        // this.$route.query.goods_name = "123456";
        this.$router.replace({
          name: "Attaching",
          query: {
            id: id,
          },
        });
        this.$router.go(0);
      } else {
        this.$router.push({
          path: "/Attaching?article_id=" + id,
        });
      }
    },
  },
};
</script>
<style lang="less">
.foot1 {
  display: none;
}
.foot {
  text-align: center;
  // padding-top: 50px;
  background-color: #000;
  color: #fff;
  width: 100%;
  // height: 500px;
  .foot_div {
    display: flex;
    justify-content: space-around;
    width: 70%;
    border-bottom: 1px solid #fff;
    margin: 10px auto;
    padding-top: 80px;
  }
  ul {
    li {
      list-style: none;
      text-align: left;
      max-width: 240px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .btnone {
    background-color: #091a41;
    color: #fff;
    height: 40px;
    width: 95%;
    margin-top: 10px;
    border: 1px solid #091a41;
    border-radius: 5px 5px;
  }
  .btntwo {
    background-color: #eac203;
    color: #fff;
    height: 40px;
    width: 95%;
    margin-top: 10px;
    border: 1px solid #eac203;
    border-radius: 5px 5px;
  }
  .btnthree {
    height: 40px;
    width: 95%;
    margin-top: 10px;
    border-radius: 5px 5px;
  }
}
@media screen and (max-width: 390px) {
  .foot {
    display: none;
  }
  .foot1 {
    display: block;

    text-align: center;
    // padding-top: 50px;
    background-color: #000;
    color: #fff;
    width: 100%;
    // height: 500px;
    .foot_div {
      display: flex;
      justify-content: space-around;
      width: 70%;
      border-bottom: 1px solid #fff;
      margin: 10px auto;
      padding-top: 80px;
    }
    ul {
      li {
        list-style: none;
        text-align: left;
      }
    }
    .btnone {
      background-color: #091a41;
      color: #fff;
      height: 40px;
      width: 95%;
      margin-top: 10px;
      border: 1px solid #091a41;
      border-radius: 5px 5px;
    }
    .btntwo {
      background-color: #eac203;
      color: #fff;
      height: 40px;
      width: 95%;
      margin-top: 10px;
      border: 1px solid #eac203;
      border-radius: 5px 5px;
    }
    .btnthree {
      height: 40px;
      width: 95%;
      margin-top: 10px;
      border-radius: 5px 5px;
    }
  }
}
</style>